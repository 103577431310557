
import { LocationProductData } from '../../types/product';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { HostedForm } from 'react-acceptjs';
import InputMask from 'react-input-mask';
import { IValues } from './valuesTypes';
import { useTranslation } from 'react-i18next';

interface BillingInformationProps {
    singlePage?: boolean;
    CardNumber?: string,
    authData: {
        apiLoginID: string
        clientKey: string,
        IsSandbox?:boolean | null
    },
    handler: any,
    isCreditInfo: boolean,
    showCreditCardErrorMessage: boolean,
    ProductData:LocationProductData


}


export const BillingInformation = ({ singlePage = false, CardNumber, authData, handler, isCreditInfo, showCreditCardErrorMessage,ProductData }: BillingInformationProps) => {
    const { values, handleChange, handleBlur } = useFormikContext<IValues>();
    const { t} = useTranslation();
    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading">{t('NewLocation.BillingInfo.billinginfo')}</h2>
               <p className='cco_form_subheading'> {t('NewLocation.BillingInfo.Instruction')}</p>
               <p style={{paddingBottom:'20px'}} ><strong>{t('NewLocation.BillingInfo.billingNote')}</strong></p>

                <div className="cco_form_row">
                    <div className="cco_form_col">
                        <div className="cco_form_wrap">
                            <div className="cco_blurb cco_blurb_4">
                                <div className="cco_blurb_main">
                                    {ProductData.ProductName}
                                </div>
                                <div className="cco_blurb_aside">
                                    ${ProductData.ProductPrice}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cco_form_wrap">
                    <div className="cco_form_row">
                        <div className="cco_form_col" style={{ marginBottom: "10px" }}>

                            <div className="cco_form_wrap">
                                <div className="cco_blurb cco_blurb_4" style={{ alignItems: "center" }}>
                                    {isCreditInfo && (
                                        <><div className="cco_blurb_main"> {t('NewLocation.BillingInfo.CardNumber')}</div>
                                            <div className="cco_blurb_aside">{CardNumber ? CardNumber : ''}</div>

                                        </>
                                    )}
                                    {!isCreditInfo && (
                                        <>
                                            <HostedForm
                                                buttonText={t('NewLocation.BillingInfo.billingbuttonText')}
                                                formButtonText={t('NewLocation.BillingInfo.formbuttonText')}
                                                containerClassName={'cco_menu_item'}
                                                buttonStyle={{ "backgroundColor": "#c67b05", "color": "#ffffff", "fontFamily": "\"DMSans Bold\"", "fontSize": "18px", "fontWeight": "700", "lineHeight": "1", "textAlign": "center", "display": "inline-flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "maxWidth": "100%", width: "100%", "padding": "10px", "border": "none" }}
                                                containerStyle={{ "backgroundColor": "#c67b05", "color": "#ffffff", "fontFamily": "\"DMSans Bold\"", "fontSize": "18px", "fontWeight": "700", "lineHeight": "1", "textAlign": "center", "display": "inline-flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "maxWidth": "100%", "minHeight": "42px", "padding": "0px 0px", "border": "2px solid #c67b05", "borderRadius": "21px" }}
                                                formHeaderText={t('NewLocation.BillingInfo.formHeaderText')}
                                                authData={authData}
                                                environment={authData?.IsSandbox?'SANDBOX':'PRODUCTION'}
                                                onSubmit={handler}
                                                billingAddressOptions={{ show: false, required: false }}
                                                errorTextStyle={{fontSize:"12px"}}
                                                 />
                                        </>
                                    )}

                                </div>
                                {!isCreditInfo && showCreditCardErrorMessage && (
                                    <p style={{ color: "red", padding: "10px 0px 0px 5px" }}>{t('NewLocation.BillingInfo.Cardinfo')}</p>
                                )}
                            </div>

                        </div>

                    </div>
                    <ul className="cco_form_fieldset">

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoFullName">{t('NewLocation.BillingInfo.FirstName')}<span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" name="FirstName" disabled={singlePage} />
                            <ErrorMessage name={'FirstName'} component={'div'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoFullName">{t('NewLocation.BillingInfo.LastName')}<span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" disabled={singlePage} name="LastName" />
                            <ErrorMessage name={'LastName'} component={'div'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoEmail">{t('NewLocation.BillingInfo.Email')} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" disabled={singlePage} name="BillToEmail" />
                            <ErrorMessage name={'BillToEmail'} component={'div'} className="cco_form_message cco_form_message_error" />


                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoPhoneBilling">{t('NewLocation.BillingInfo.Phone')} <span style={{ color: '#db3a3a' }}>*</span></label>

                            <InputMask
                                type={'tel'}
                                id={'ccoPhoneBilling'}
                                mask="+1 (999) 999-9999"
                                value={values.BillingPhone}
                                onChange={handleChange('BillingPhone')}
                                onBlur={handleBlur('BillingPhone')}
                            />

                            <ErrorMessage component={'div'} name={'BillingPhone'} className="cco_form_message cco_form_message_error" />
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoCountry">
                            {t('NewLocation.BillingInfo.Country')}<span style={{ color: '#db3a3a' }}>*</span>
                            </label>
                            <Field type="text" disabled={singlePage} id="ccoCountry" name="BillingCountry" />
                            <ErrorMessage name={'BillingCountry'} component={'div'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoFullName">{t('NewLocation.BillingInfo.Address')}<span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" disabled={singlePage} name="Address" />
                            <ErrorMessage name={'Address'} component={'div'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                            <label htmlFor="ccoCity">
                            {t('NewLocation.BillingInfo.City')} <span style={{ color: '#db3a3a' }}>*</span>
                            </label>
                            <Field type="text" disabled={singlePage} id="ccoCity" name="City" />
                            <ErrorMessage name={'City'} component={'div'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                            <label htmlFor="ccoState">{t('NewLocation.BillingInfo.State')}<span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" disabled={singlePage} name="State" />
                            <ErrorMessage name={'State'} component={'div'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                            <label htmlFor="ccoFullName">{t('NewLocation.BillingInfo.Zip')} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" disabled={singlePage} name="Zip" />
                            <ErrorMessage name={'Zip'} component={'div'} className="cco_form_message cco_form_message_error" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
