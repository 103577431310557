import classNames from 'classnames';
interface IAvailableCredential {
    name:string;
    id?:number;
    type:string;
    setDocumentType:(value:string)=>void
}

export const GeneratePDFAccordion = ({name,type,setDocumentType }: IAvailableCredential) => {
    return (
        <a href={"#"} onClick={(e)=>{e.preventDefault();setDocumentType(type)}} style={{ color: 'rgb(102, 102, 102)' }}>
        <li className={classNames('cco_accordion_item ac_card_hover', { active: false })} style={{ listStyle: 'none' }}>
            <div className="cco_accordion_item_header_custom">
                    <div className="cco_text cco_text_3">
                        <div className="cco_text_inner">
                            <h3>{name}</h3>
                        </div>
                    </div>
              </div>
        </li>
        </a>
    );
};
