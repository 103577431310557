import React from "react";
import { useGlobalContext } from "../../hooks/Helper/GlobalContext";
import { customURLs, getToken } from "../../tools/utils";

interface ButtonProps {
  href?: string;
  text: string;
  target?: string;
  onClick?: (event: any) => void;
  className: string;
}

export const Button = ({
  className,
  onClick,
  text,
  href,
  target,
}: ButtonProps) => {
  return (
    <a
      style={{ height: "auto" }}
      className={`${className}`}
      href={`${href}`}
      onClick={onClick}
      target={target}
    >
      {text}
    </a>
  );
};
