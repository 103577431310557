import React, { useState, useEffect, useRef } from 'react';

interface NotificationBarProps {
  backgroundColor?: string;
  textColor?: string;
  buttonText?: string;
  buttonColor?: string;
  buttonLink?: string;
  enable?: boolean;
  message?: string;
  DismissColor?: string;
  allowDismiss?: boolean;
  buttonTextColor?: string;
  onDismiss?: () => void;
  onHeightChange?: (height: number) => void;
  positionIndex?: number; // Index for calculating dynamic top margin
  DisableButton?: boolean; // Disable button display if true
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  DisableButton = false,  // Default to false if not provided
  backgroundColor,
  textColor,
  buttonText,
  buttonColor,
  buttonLink,
  enable,
  message,
  DismissColor,
  buttonTextColor,
  allowDismiss,
  onDismiss,
  onHeightChange,
  positionIndex = 0,
}) => {
  const [visible, setVisible] = useState(enable);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bannerRef.current && visible) {
      const height = bannerRef.current.getBoundingClientRect().height;
      if (onHeightChange) {
        onHeightChange(height); // Notify parent about the height change
      }
    }

    return () => {
      if (onHeightChange && bannerRef.current) {
        onHeightChange(0); // Reset height when the banner is dismissed
      }
    };
  }, [visible, onHeightChange]);

  if (!visible) return null;

  const handleClose = () => {
    setVisible(false);
    if (onDismiss) onDismiss();
  };

  // Log the DisableButton flag to check if it's working as expected
  console.log('DisableButton:', DisableButton);  // Check the value of DisableButton

  return (
    <div
      ref={bannerRef}
      style={{
        backgroundColor,
        color: textColor,
        padding: '10px',
        width: '100%',
        textAlign: 'center',
        zIndex: 1000,
        position: 'fixed',
        top: `${positionIndex * 70}px`, // Dynamically space banners vertically
        transition: 'top 0.3s ease',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 1, textAlign: 'left', marginLeft: '10px' }}>
          <div dangerouslySetInnerHTML={{ __html: message || '' }}></div>
        </div>

        {allowDismiss && (
          <button
            onClick={handleClose}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              color: DismissColor || '#000',
              cursor: 'pointer',
              margin: '0 10px',
              marginRight: '30px',
            }}
          >
            &#x2715;
          </button>
        )}
      </div>

      {/* Button should only appear if DisableButton is false */}
      {DisableButton && buttonText && buttonLink && (
        <a
          href={buttonLink}
          style={{
            backgroundColor: buttonColor,
            color: buttonTextColor,
            padding: '5px 10px',
            borderRadius: '3px',
            textDecoration: 'none',
          }}
        >
          {buttonText}
        </a>
      )}
    </div>
  );
};

export default NotificationBar;

