import { useQuery } from "@tanstack/react-query";
import { request } from "../services/request";
import { RootObject1 } from "../types/prerequisite";

export const usePrerequisiteTree = (credentialId: string, language?: string | null) => {
    return useQuery<RootObject1, Error>(
        ['credential-prerequisite-tree', credentialId, language], // Include language in the query key
        async () => {
            const jsonResponse = await request({
                endpoint: 'credentials/credential-prerequisite-tree',
                data: {
                    credentialId: credentialId,
                    language: language, // Pass the language if provided
                },
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled: !!credentialId, // Only enable query if credentialId is present
            refetchOnWindowFocus: false,
        }
    );
};
