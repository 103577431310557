import { screenedValues } from '../../../tools/constants';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import { IValues } from '../valuesTypes';
import { useTranslation } from 'react-i18next';

export const BillingInformation = ({
    handler,
    editable = true,
    CreditCard,
    Amount
}: {
    handler?: (step: number) => void;
    editable?: boolean;
    CreditCard:string;
    Amount:number
}) => {
    const { t} = useTranslation();
    const [isExpanded, setIsExpanded] = useState(true);
    const { values, errors, touched, submitCount } = useFormikContext<IValues>();

    const isValues = useMemo(
        () => screenedValues[7].every(field => values[field] ),
        [screenedValues[7]]
    );

    const isFormCompleted = useMemo(
        () => screenedValues[7].every(field => (!errors[field] || touched[field]) && isValues),
        [errors, touched]
    );
 
    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded,
            })}
        >
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>7. {t('NewLocation.BillingInfo.Payment')}</h3>
                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted,
                            })}
                        >
                            {isFormCompleted
                                ? t('NewLocation.BillingInfo.Completed')
                                : submitCount < 1 
                                ? 'In Progress'
                                : t('NewLocation.BillingInfo.FillFields')}
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    className="cco_collapse_toggle"
                    onClick={() => setIsExpanded(prevState => !prevState)}
                >
                    <span className="cco_collapse_toggle_text">{t('NewLocation.BillingInfo.View')}</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.CardNumber')}</div>
                        <div className="cco_blurb_value">
                            <strong>{CreditCard || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.Amount')}</div>
                        <div className="cco_blurb_value">
                            <strong>${Amount || 'Failed to get'}</strong>
                        </div>
                    </div>
                <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.FirstName')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.FirstName || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.LastName')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.LastName || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.Email')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.BillToEmail || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.Phone')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.BillingPhone || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.Address')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.Address || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.Country')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.BillingCountry || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.City')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.City || 'Not completed'}</strong>
                        </div>
                    </div>
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('NewLocation.BillingInfo.State')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.State || 'Not completed'}</strong>
                        </div>
                    </div>

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(5);
                                    e.preventDefault();
                                }}
                            >
                              {t('NewLocation.BillingInfo.Edit')}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
