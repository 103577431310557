import type { IValuesPrac } from './valuesTypes';

import { Loader } from '../../components/Loader';
import { useStates } from '../../hooks/useStates';
import { useCountries } from '../../hooks/useCountries';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { useTimezone } from '../../hooks/useTimezones';
import { useTranslation } from 'react-i18next';


interface ILocationInformationProps {
    singlePage?: boolean;
}

export const LocationInformation = ({ singlePage = false }: ILocationInformationProps) => {
    const { t } = useTranslation();
    const countriesQuery = useCountries();
    const timezoneQuery=useTimezone();
    const formik = useFormikContext<IValuesPrac>();
    const countriesData = countriesQuery.data?.data.Data || [];
    const timezonesData = timezoneQuery.data?.data.Data || [];


    const statesQuery = useStates('default' === formik.values.country ? undefined : formik.values.country);
    const statesLoading = 'idle' !== statesQuery.fetchStatus && statesQuery.isLoading;
    const statesData = statesQuery.data?.data.Data || [];

    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading">1. {t('NewPracLocation.LocationInfromation.TestSiteInformation')} </h2>

                <div className="cco_form_wrap">
                    <ul className="cco_form_fieldset">
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoCompanyName">{t('NewPracLocation.LocationInfromation.TestingFacilityCompanyName')} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCompanyName" name="companyName" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'companyName'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoStreet1">{t('NewPracLocation.LocationInfromation.Street1')} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoStreet1" name="street1" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'street1'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoStreet2">
                             {t('NewPracLocation.LocationInfromation.Street2')} <small>{t('NewPracLocation.LocationInfromation.str2')}</small>
                            </label>
                            <Field type="text" id="ccoStreet2" name="street2" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'street2'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoCountry"> {t('NewPracLocation.LocationInfromation.Country')} <span style={{ color: '#db3a3a' }}>*</span></label>

                            {countriesQuery.isLoading && <Loader scale={0.6} fieldset={true} />}

                            <Field as="select" id="ccoCountry" name="country" disabled={countriesQuery.isLoading || singlePage}>
                                <option  value="default" disabled={true}>
                                    {countriesQuery.isLoading ? 'Loading...' : t('NewPracLocation.LocationInfromation.Selectacountry')}
                                </option>

                                {countriesData.map(country => {
                                    return (
                                        <option key={country.CountryId} value={country.CountryId}>
                                            {country.CountryName}
                                        </option>
                                    );
                                })}
                            </Field>

                            <ErrorMessage component={'div'} name={'country'} className="cco_form_message cco_form_message_error" />

                            {countriesQuery.error && <div className="cco_form_message cco_form_message_error">{countriesQuery.error.message}</div>}

                            {countriesQuery.data && countriesData.length < 1 && (
                                <div className="cco_form_message cco_form_message_error">{t('NewPracLocation.LocationInfromation.noCountry')}</div>
                            )}
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoCity">{t('NewPracLocation.LocationInfromation.City')} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" name="city" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'city'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li
                            className="cco_form_fieldset_item cco_form_fieldset_item_medium"
                            data-tooltip={'default' === formik.values.country ? 'Select a country first' : undefined}>
                            <label htmlFor="ccoState">{t('NewPracLocation.LocationInfromation.State')} <span style={{ color: '#db3a3a' }}>*</span></label>

                            {statesLoading && <Loader scale={0.6} fieldset={true} />}

                            <Field as="select" id="ccoState" name="state" disabled={'default' === formik.values.country || statesLoading || singlePage}>
                                <option value="default" disabled={true}>
                                    {statesLoading ? 'Loading...' : t('NewPracLocation.LocationInfromation.Selectastate') }
                                </option>

                                {statesData.map(state => (
                                    <option key={state.ProvinceId} value={state.ProvinceId}>
                                        {state.ProvinceName}
                                    </option>
                                ))}
                            </Field>

                            <ErrorMessage component={'div'} name={'state'} className="cco_form_message cco_form_message_error" />

                            {statesQuery.error && <div className="cco_form_message cco_form_message_error">{statesQuery.error.message}</div>}

                            {statesQuery.data && statesData.length < 1 && (
                                <div className="cco_form_message cco_form_message_error">{t('NewPracLocation.LocationInfromation.noState')}</div>
                            )}
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoZip"> {t('NewPracLocation.LocationInfromation.Zip')} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoZip" name="zip" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'zip'} className="cco_form_message cco_form_message_error" />
                        </li>
                  

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoSecureFacility">{t('NewPracLocation.LocationInfromation.zipreq')} <span style={{ color: '#db3a3a' }}>*</span></label>

                            <Field as="select" id="ccoSecureFacility" name="isASecureFacility">
                                <option value="default" disabled={true}>
                                {t('NewPracLocation.LocationInfromation.Selectanoption')}
                                </option>

                                <option value="yes">{t('NewPracLocation.LocationInfromation.Yes')}</option>
                                <option value="no">{t('NewPracLocation.LocationInfromation.No')}</option>
                            </Field>

                            <ErrorMessage component={'div'} name={'isASecureFacility'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoTimezone">{t('NewPracLocation.LocationInfromation.Timezone')} <span style={{ color: '#db3a3a' }}>*</span></label>

                            {timezoneQuery.isLoading && <Loader scale={0.6} fieldset={true} />}

                            <Field as="select" id="ccoTimezone" name="timezone" disabled={timezoneQuery.isLoading || singlePage}>
                                <option value="default" disabled={true}>
                                    {timezoneQuery.isLoading ? 'Loading...' : t('NewPracLocation.LocationInfromation.Selectatimezone')}
                                </option>

                                {timezonesData.map(timezone => {
                                    return (
                                        <option key={timezone.TimeZoneId} value={timezone.TimeZoneId}>
                                            {timezone.PrimaryName}
                                        </option>
                                    );
                                })}
                            </Field>

                            <ErrorMessage component={'div'} name={'timezone'} className="cco_form_message cco_form_message_error" />

                            {timezoneQuery.error && <div className="cco_form_message cco_form_message_error">{timezoneQuery.error.message}</div>}

                            {timezoneQuery.data && timezonesData.length < 1 && (
                                <div className="cco_form_message cco_form_message_error">{t('NewPracLocation.LocationInfromation.Notimezone')}</div>
                            )}
                        </li>

                        {'yes' === formik.values.isASecureFacility && (
                            <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                <label htmlFor="additionalSecurity">
                                {t('NewPracLocation.LocationInfromation.provide')}<span style={{ color: '#db3a3a' }}>*</span>
                                </label>

                                <Field rows={10} as="textarea" id="additionalSecurity" name="additionalSecurity" />

                                <ErrorMessage
                                    component={'div'}
                                    name={'additionalSecurity'}
                                    className="cco_form_message cco_form_message_error"
                                />
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
