// MenuItem.js
import React, { useState } from 'react';

const MenuItem = ({ defaultIcon, hoverIcon, link, text, altText }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <li
      className="cco_menu_item cco_menu_items"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? hoverIcon : defaultIcon}
        alt={altText}
        className="menu-icon"
      />
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{ marginLeft: '-40px' }}
      >
        {text}
      </a>
    </li>
  );
};

export default MenuItem;

