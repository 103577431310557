import { useMutation } from '@tanstack/react-query';
import { request } from '../services/request';

export const useLocationRenewal = () => {
    return useMutation(async (data: { body: any; type:string }) => {
        const jsonResponse = await request({
            data:data.body,
            method: 'PUT',
            endpoint: data.type==='eot'?'locations/renew-eot-ts':'locations/renew-pe-ts',
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};
