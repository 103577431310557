import { Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

export const Attestation = () => {
    const { t } = useTranslation();
    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading">6.  {t('NewPracLocation.Attestation.Attestation')}</h2>

                <div className="cco_form_wrap">
                    <div className="cco_text cco_text_8">
                        <div className="cco_text_inner"> {t('NewPracLocation.Attestation.req')}</div>
                    </div>
                    <ul className="cco_form_fieldset">
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoParking" type="checkbox" name="attestation" value="parking" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                                {t('NewPracLocation.Attestation.ade')}
                            </label>
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoRestroom" type="checkbox" name="attestation" value="restroom" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                                {t('NewPracLocation.Attestation.availible')}
                            </label>
                        </li>
                        {/* Newly Added Field in Practical Location */}
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoRestroom" type="checkbox" name="attestation" value="pretest" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                                {t('NewPracLocation.Attestation.pretest')}
                            </label>
                        </li>
                    </ul>

                    <ErrorMessage component={'div'} name={'attestation'} className="cco_form_message cco_form_message_error" />
                </div>
            </div>
        </div>
    );
};
