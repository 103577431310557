// import React, { ReactNode, createContext, useContext } from 'react';
// import { useContactAccessControl } from '../useContactAccessControl';
// import { ContactAccessControl } from '../../types/dashboard';
// import { useTranslation } from 'react-i18next';


// export enum AccessControl {
  
//   Normal = '',
//   Watched = '',
//   Delayed = 'Delayed',
//   Suspended = 'Suspended',
//   Locked = 'Locked',
// }
// const { t } = useTranslation();

// type MessageObject = {
//   red: string;
//   orange: string;
//   yellow: string;
//   photo: string;
//   [key: string]: string; 
// };

// type ProviderMessageObject = {
//   red: string;
//   orange: string;
//   yellow: string;
//   [key: string]: string;
// };



// const candidateBannerMessage: MessageObject = {
//   red: t('Banner.CandidateAccessControl.red'),
//   orange: t('Banner.CandidateAccessControl.orange'),
//   yellow: t('Banner.CandidateAccessControl.yellow'),
//   photo: t('Banner.CandidateAccessControl.note')
// };


// const providerBannerMessage: ProviderMessageObject = {
//   red: 'Your testing personnel account is locked. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812',
//   orange: 'Your testing personnel account is currently suspended. Some account and related data may still be visible. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
//   yellow: 'Your testing personnel account is subject to the test administration advanced scheduling requirement. You may only create or be assigned to a test administration with a start date/time at least two business days into the future. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
// };

// export const getBannerColor = (accessControl: string) => {
  
//   return accessControl === AccessControl.Locked
//     ? 'red'
//     : accessControl === AccessControl.Suspended
//     ? 'orange'
//     : accessControl === AccessControl.Delayed
//     ? 'yellow'
//     : '';
// };

// interface IGlobalContext {
//   CandidateAccessControl: string;
//   ProviderAccessControl: string;
//   CandidateBannerColor: string;
//   ProviderBannerColor: string;
//   ProviderBannerMessage: string;
//   CandidateBannerMessage: string;
//   PhotoBannerMessage: string;
//   Color: string;
//   FullName:string;
//   CandidateId:string;
// }

// const GlobalContext = createContext<IGlobalContext | undefined>(undefined);

// export const useGlobalContext = () => {
  
  
//   const context = useContext(GlobalContext);
//   if (!context) {
//     throw new Error('useGlobalContext must be used within a GlobalContextProvider');
//   }
//   return context;
// };

// interface GlobalContextProviderProps {
//   children: ReactNode;
// }


// export const GlobalContextProvider = ({ children }:GlobalContextProviderProps) => {
  
//   // const userData = getUserBasicData();
//   const accessControlQuery = useContactAccessControl();

//   const accessControlData = accessControlQuery.data?.data?.Data as ContactAccessControl;

//   const CandidateAccessControl=accessControlData?.CandidateAccessControl;
//   const ProviderAccessControl=accessControlData?.ProviderAccessControl;

//   const candidateBannerColor = getBannerColor(CandidateAccessControl);
//   const providerBannerColor = getBannerColor(ProviderAccessControl);

//   const contextValues = {
//     CandidateAccessControl: CandidateAccessControl,
//     ProviderAccessControl: ProviderAccessControl,
//     CandidateBannerColor: candidateBannerColor,
//     ProviderBannerColor: providerBannerColor,
//     ProviderBannerMessage: providerBannerMessage[providerBannerColor],
//     CandidateBannerMessage: candidateBannerMessage[candidateBannerColor],
//     PhotoBannerMessage: candidateBannerMessage.photo,
//     Color: candidateBannerColor === 'yellow' || providerBannerColor === 'yellow' ? 'black' : '',
//     CandidateId:accessControlData?.CandidateId || '',
//     FullName:accessControlData?.FullName || ''
//   };

//   return (
//     <GlobalContext.Provider value={contextValues}>
//       {children}
//     </GlobalContext.Provider>
//   );
// };



import React, { ReactNode, createContext, useContext } from 'react';
import { useContactAccessControl } from '../useContactAccessControl';
import { ContactAccessControl } from '../../types/dashboard';
import { useTranslation } from 'react-i18next';

export enum AccessControl {
  Normal = '',
  Watched = '',
  Delayed = 'Delayed',
  Suspended = 'Suspended',
  Locked = 'Locked',
}

type MessageObject = {
  red: string;
  orange: string;
  yellow: string;
  photo: string;
  [key: string]: string; 
};

type ProviderMessageObject = {
  red: string;
  orange: string;
  yellow: string;
  [key: string]: string;
};

export const getBannerColor = (accessControl: string) => {
  return accessControl === AccessControl.Locked
    ? 'red'
    : accessControl === AccessControl.Suspended
    ? 'orange'
    : accessControl === AccessControl.Delayed
    ? 'yellow'
    : '';
};

interface IGlobalContext {
  CandidateAccessControl: string;
  ProviderAccessControl: string;
  CandidateBannerColor: string;
  ProviderBannerColor: string;
  ProviderBannerMessage: string;
  CandidateBannerMessage: string;
  PhotoBannerMessage: string;
  Color: string;
  FullName: string;
  CandidateId: string;
}

const GlobalContext = createContext<IGlobalContext | undefined>(undefined);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider');
  }
  return context;
};

interface GlobalContextProviderProps {
  children: ReactNode;
}

export const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const { t } = useTranslation(); // Add useTranslation here

  const accessControlQuery = useContactAccessControl();
  const accessControlData = accessControlQuery.data?.data?.Data as ContactAccessControl;

  const CandidateAccessControl = accessControlData?.CandidateAccessControl || AccessControl.Normal;
  const ProviderAccessControl = accessControlData?.ProviderAccessControl || AccessControl.Normal;

  const candidateBannerColor = getBannerColor(CandidateAccessControl);
  const providerBannerColor = getBannerColor(ProviderAccessControl);

  // Moved providerBannerMessage inside the component
  const providerBannerMessage: ProviderMessageObject = {
    red: t('Banner.testingpersonalBanner.red'),
    orange: t('Banner.testingpersonalBanner.orange'),
    yellow: t('Banner.testingpersonalBanner.yellow'),
  };

  const candidateBannerMessage: MessageObject = {
    red: t('Banner.CandidateAccessControl.red'),
    orange: t('Banner.CandidateAccessControl.orange'),
    yellow: t('Banner.CandidateAccessControl.yellow'),
    photo: t('Banner.CandidateAccessControl.note'),
  };

  const contextValues: IGlobalContext = {
    CandidateAccessControl,
    ProviderAccessControl,
    CandidateBannerColor: candidateBannerColor,
    ProviderBannerColor: providerBannerColor,
    ProviderBannerMessage: providerBannerMessage[providerBannerColor] || '',
    CandidateBannerMessage: candidateBannerMessage[candidateBannerColor] || '',
    PhotoBannerMessage: candidateBannerMessage.photo,
    Color: (candidateBannerColor === 'yellow' || providerBannerColor === 'yellow') ? 'black' : '',
    CandidateId: accessControlData?.CandidateId || '',
    FullName: accessControlData?.FullName || '',
  };

  return (
    <GlobalContext.Provider value={contextValues}>
      {children}
    </GlobalContext.Provider>
  );
};

