import type { IValuesPrac } from '../../../containers/NewPracLocation/valuesTypes';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';


import { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { screenedValuesPrac } from '../../../tools/constants';

const choices = {
    parking: 'Adequate Parking for the number of vehicles anticipated',
    restroom: 'Restroom is available on-site',
    pretest:'A pre-test briefing area for candidates waiting to test (unable to observe candidates who are live testing)'
};

export const Attestation = ({ handler, editable = true }: { handler?: (step: number) => void; editable?: boolean }) => {
    const { values, errors, touched, submitCount } = useFormikContext<IValuesPrac>();

    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();

    const isFormCompleted = useMemo(() => screenedValuesPrac[4].every(field => !errors[field] && touched[field]), [errors, touched]);

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded
            })}>
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>6. {t('SummaryPracSite.Attestation.Attestation')}</h3>

                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted && touched.attestation
                            })}>
                            {isFormCompleted ? t('SummaryPracSite.Attestation.com') : submitCount < 1 ? t('SummaryPracSite.Attestation.inprog') : t('SummaryPracSite.Attestation.note')}
                        </div>
                    </div>
                </div>

                <button type="button" className="cco_collapse_toggle" onClick={() => setIsExpanded(prevState => !prevState)}>
                    <span className="cco_collapse_toggle_text">{t('SummaryPracSite.Attestation.View')}</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    {!values.attestation.length && <div className="cco_blurb cco_blurb_error cco_blurb_3">{t('SummaryPracSite.Attestation.no')}</div>}

                    {values.attestation.length > 0 &&
                        (Object.keys(choices) as (keyof typeof choices)[]).map((choice, index) => (
                            <div
                                className={classNames('cco_blurb cco_blurb_3', {
                                    cco_blurb_success: values.attestation.includes(choice),
                                    cco_blurb_error: !values.attestation.includes(choice)
                                })}
                                key={index}>
                                {choices[choice]}
                            </div>
                        ))}

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(4);
                                    e.preventDefault();
                                }}>
                                {t('SummaryPracSite.Attestation.Edit')}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
