import type { IValues } from './valuesTypes';
import image1 from "../../assets/images/TFP_Sample.jpg"
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LocationDoorSign = () => {
    const { values, errors, setFieldTouched, setFieldValue, touched } = useFormikContext<IValues>();
    const { t} = useTranslation();
    const [fileError, setFileError] = useState<string | null>(null);
    const [sitePlanError, setSitePlanError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0] : null;

        if (!file) return;

        if (file.name.toLowerCase().endsWith('.jfif')) {
            setError('This file type is not supported');
            setFieldValue(fieldName, null); // Reset field value
            setFieldTouched(fieldName, false); // Prevent validation error
        } else {
            setError(null); // Clear error if file is valid
            setFieldTouched(fieldName, true);
            setFieldValue(fieldName, file);
        }
    };
    return (
        <div className="cco_form_row">
            <div className="cco_form_col cco_form_col_1">
                <h2 className="cco_form_heading"> {t("NewLocation.TestingFacilityincludingSignage.Photo")} </h2>

                <div className="cco_form_wrap">
                    <div className="cco_form_fieldset cco_form_fieldset_2">
                        <div className="cco_form_fieldset_item">
                            <label htmlFor="ccoDoorSign">
                            {t("NewLocation.TestingFacilityincludingSignage.upload")}  <small>{t("NewLocation.TestingFacilityincludingSignage.picdetail")}  </small>
                            </label>

                            <div className="cco_form_field cco_form_field_0">
                                <div className="cco_form_field_body">
                                    <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoDoorSign">
                                        {(values.doorSign instanceof File && values.doorSign.type.includes('image')) ||
                                        ('string' === typeof values.doorSign && values.doorSign && !values.doorSign.includes('.pdf')) ? (
                                            <>
                                                <img
                                                    src={values.doorSign instanceof File ? URL.createObjectURL(values.doorSign) : values.doorSign}
                                                    onLoad={e => {
                                                        const src = e.currentTarget.src;

                                                        if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                    }}
                                                    alt=""
                                                />
                                                <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">{t("NewLocation.TestingFacilityincludingSignage.upload")}</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="cco_form_field_label_icon cco_form_field_label_icon_camera"></span>
                                                <span className="cco_form_field_label_caption">{t("NewLocation.TestingFacilityincludingSignage.upload")}</span>
                                            </>
                                        )}
                                    </label>

                                     <Field
                                        type={'file'}
                                        name={'doorSign'}
                                        value={undefined}
                                        id={'ccoDoorSign'}
                                        className={'cco_form_field_input'}
                                        accept={'.pdf, image/png, image/jpeg'}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e, 'doorSign', setFileError)}/>
                                        </div>
        
                                        <div className="cco_form_field_aside">
                                        {fileError && (
                                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_error">
                                                    {fileError}
                                                </div>
                                            )}
                                    {values.doorSign && !errors.doorSign && touched.doorSign && (
                                        <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                            {values.doorSign instanceof File ? values.doorSign.name : null /* values.doorSign */}
                                        </div>
                                    )}

                                    <ErrorMessage
                                        name={'doorSign'}
                                        component={'div'}
                                        className="cco_form_message cco_form_message_has_icon cco_form_message_error"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="cco_form_fieldset_item">
                            <div className="cco_form_label">{t("NewLocation.TestingFacilityincludingSignage.sampletesting")}</div>

                            <img className="cco_form_img" srcSet={image1} alt="" />
                        </div>
                    </div>
                </div>
            </div>

                <div className="cco_form_col cco_form_col_0">
                <h2 className="cco_form_heading">3. {t('NewLocation.TestingSite.TestSiteCapacity')}</h2>

                <div className="cco_form_wrap">
                    <ul className="cco_form_fieldset">
                    
                        <li className="cco_form_fieldset_item">
                        <h2 className="cco_form_heading">A. {t('NewLocation.TestingSite.hA')}</h2>
      <p className='cco_form_note'>
      {t('NewLocation.TestingSite.para1A')}
      </p>
      <ul className='cco_form_note' style={{marginLeft:'20px'}} >
        <li>
          ○  {t('NewLocation.TestingSite.list1')}
          <ul  style={{marginLeft:'20px'}} >
            <li>▪️ {t('NewLocation.TestingSite.list2')}</li>
            <li>▪️ {t('NewLocation.TestingSite.list3')}</li>
          </ul>
     <strong><p style={{marginLeft:'10px', fontSize:'15px', marginBottom:'10px'}}><em>{t('NewLocation.TestingSite.Note1A')}</em></p></strong> 
        </li>
        <li>
          ○ {t('NewLocation.TestingSite.list4')}
          <ul style={{marginLeft:'20px', marginBottom:'10px'}}>
            <li>▪️ {t('NewLocation.TestingSite.list5')}</li>
            <li>▪️ {t('NewLocation.TestingSite.list6')}</li>
          </ul>
        </li>
        <li>
        ○ {t('NewLocation.TestingSite.list7')}
        </li>
        <li>
        ○ {t('NewLocation.TestingSite.list8')}
        </li>
        <strong><p style={{marginLeft:'10px', fontSize:'15px', marginBottom:'10px'}}><em>{t('NewLocation.TestingSite.Note2A')}</em></p></strong> 
      </ul>
     

      <p className='cco_form_note'>
      {t('NewLocation.TestingSite.para2A')} <a href=" https://www.nccco.org/techreq" target="_blank" rel="noopener noreferrer">{t('NewLocation.TestingSite.click')}</a>.
      </p>
      
      <p className="cco_form_note">
      {t('NewLocation.TestingSite.para3A')}{' '}
                                <a href="https://www.speedtest.net/" target="_blank" rel="noopener noreferrer">
                                {t('NewLocation.TestingSite.link')}
                                </a>
                                . {t('NewLocation.TestingSite.performthetest')}<br/></p><br/>

                             <h4 className="cco_form_heading">{t('NewLocation.TestingSite.capacity')}</h4>
                            <label htmlFor="ccoTestingStations">
                            {t('NewLocation.TestingSite.factors')}
                            </label>

                            <Field type="text" id="ccoTestingStations" name="numberOfCandidateTestingStations" />

                            <ErrorMessage component={'div'} name={'numberOfCandidateTestingStations'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item">
                        <h4 className="cco_form_heading">{t('NewLocation.TestingSite.attestation3')}</h4>
                            <label htmlFor="ccoInternetSpeed">
                            {t('NewLocation.TestingSite.conformation')}
                            </label>

                            <Field as="select" name="internetSpeed" id="ccoInternetSpeed">
                                <option value="default" disabled={true}>
                                {t('NewLocation.TestingSite.option')}
                                </option>

                                <option value="yes">{t('NewLocation.TestingSite.Yes')}</option>
                                <option value="no">{t('NewLocation.TestingSite.No')}</option>
                            </Field>

                            <ErrorMessage component={'div'} name={'internetSpeed'} className="cco_form_message cco_form_message_error" />

                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
