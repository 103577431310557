import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import English from './resources/en'
import Spanish from './resources/es'
const storedLanguage = localStorage.getItem('i18nextLng');




i18n.use(initReactI18next)
  .init({
    resources:{
      English,
      Spanish
    },
    lng:storedLanguage ||'English',
    fallbackLng: 'Spanish', 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;