import { IValuesCAD } from '../containers/NewCustomCAD/valuesTypes';
import type { IValues } from '../containers/NewLocation/valuesTypes';
 import type { IValuesPrac } from '../containers/NewPracLocation/valuesTypes';

export interface IScreenedValues {
    [key: number]: (keyof IValues)[];
}

export interface IScreenedValuesPrac {
    [key: number]: (keyof IValuesPrac)[];
}
export interface IScreenedValuesCAD {
    [key: number]: (keyof IValuesCAD)[];
}

export const screenedValues: IScreenedValues = {
    0: ['companyName', 'street1', 'street2', 'country', 'city', 'state', 'zip', 'isASecureFacility','timezone', 'additionalSecurity'],
    1: ['doorSign', 'internetSpeed', 'numberOfCandidateTestingStations'],
    2: ['sitePlan'],
    3: ['fullName', 'phoneNumber', 'email', 'emailConfirmation', 'termsAndConditions'],
    4: ['attestation'],
    5: [],
    7: ['Address', 'BillToEmail', 'FirstName', 'LastName', 'Zip', 'State', 'City', 'BillingCountry', 'BillingPhone']
};

export const screenedValuesPrac: IScreenedValuesPrac = {
    0: ['companyName', 'street1', 'street2', 'country', 'city', 'state', 'zip', 'timezone','isASecureFacility', 'additionalSecurity'],
    1: ['doorSign', 'sitePlan'],
    2: ['PracSiteTypes','PracticalAttestation'],
    3: ['fullName', 'phoneNumber', 'email', 'emailConfirmation', 'termsAndConditions'],
    4: ['attestation'],
    5: [],
    7: ['Address', 'BillToEmail', 'FirstName', 'LastName', 'Zip', 'State', 'City', 'BillingCountry', 'BillingPhone']
};

export const screenedValuesCAD:IScreenedValuesCAD = {
    0:[],
    1:['make','serialNumber','workingArea','cranePosition','truckBedLength','isBoomTruck','isBoomTruck','model','maxRelatedCapacity','boomLength','allowableLine','diameterTestWeight','linePullChart','rangeDiagram','loadChart'],
    2:['Address', 'BillToEmail', 'FirstName', 'LastName', 'Zip', 'State', 'City', 'BillingCountry', 'BillingPhone']
};



