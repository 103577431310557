import classNames from 'classnames';
import React from 'react';

interface LoaderProps {
    scale?: number;
    fieldset?: boolean;
    color?:string
}

export const Loader = ({ scale, fieldset , color}: LoaderProps) => {
    return (
        <div
            className={classNames('ccs_loader', {
                ccs_loader_fieldset: fieldset,
            })}
            style={
                scale
                    ? {
                          transform: `scale(${scale})`,
                          color:`${color}`
                      }
                    : undefined
            }
        >
            <div className="ccs_loader_item"></div>
            <div className="ccs_loader_item"></div>
            <div className="ccs_loader_item"></div>
            <div className="ccs_loader_item"></div>
        </div>
    );
};
