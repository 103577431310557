import React from 'react';
import Webcam from 'react-webcam';
import logo from '../../assets/images/sample_144x144.jpg'
import { useTranslation } from 'react-i18next';

import Cropper, { ReactCropperElement } from 'react-cropper';

interface IPhotoProps {
    image: string | null;
    isPhotoCroped: boolean;
    PhotoBlob: Blob | null;
    useCam: boolean;
    useWebCam: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    cropperPhotoRef: React.RefObject<ReactCropperElement>;
    resetImage: () => void;
    handleZoomIn: () => void;
    handleZoomOut: () => void;
    rotateImage: () => void;
    getCropData: () => void;
    webcamRef: React.RefObject<Webcam>;
    captureImageFromWebcam: () => void;
    isMobile: boolean;
    showFaceError: boolean;
    faceError:string;
}

export const PhotoUpload = ({
    image,
    isPhotoCroped,
    PhotoBlob,
    useCam,
    useWebCam,
    onChange,
    cropperPhotoRef,
    resetImage,
    handleZoomIn,
    handleZoomOut,
    getCropData,
    webcamRef,
    captureImageFromWebcam,
    isMobile,
    showFaceError,
    rotateImage,
    faceError
}: IPhotoProps) => {
    const { t } = useTranslation();
    return (
        <div className="cco_form_row">
            <div className="cco_form_col cco_form_col_1">
                <div className="cco_form_wrap">
                    <div className="cco_form_fieldset cco_form_fieldset_2">
                        <div className="cco_form_fieldset_item">
                            <label htmlFor="ccoDoorSign">
                            1. <b>{t('PhotoManagement.PhotoUpload.Submission')}</b> {t('PhotoManagement.PhotoUpload.Eg')}
                            </label>
                            <div style={{ fontSize: 12, fontStyle: 'italic' }}>
                                <small>
                                    {t('PhotoManagement.PhotoUpload.Note')}
                                </small>
                            </div>
                            <div className="cco_form_field cco_form_field_0">
                                <div className="cco_form_field_body_p">
                                    <label className="cco_form_field_label_small ccs_loader_relative_container" style={{ margin: '10px', display: image ? 'none' : 'block' }} htmlFor="ccoT" >
                                        {(isPhotoCroped && PhotoBlob) ? (
                                            <>
                                                <input type='file' id='ccoT2' hidden onChange={onChange} />
                                                <img src={URL.createObjectURL(PhotoBlob)}

                                                    id='ccoT2'
                                                    alt="user profile picture"
                                                />
                                                <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">
                                                    {t('PhotoManagement.PhotoUpload.Update')}</span>
                                            </>
                                        ) : (
                                            <>
                                                <input type='file' id='ccoT' hidden onChange={onChange} />
                                                <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                                <span className="cco_form_field_label_caption">{t('PhotoManagement.PhotoUpload.Upload')}</span>
                                            </>
                                        )}
                                    </label>

                                    {!useCam && !isMobile && !image && !isPhotoCroped &&
                                        <label className="cco_form_field_label_small ccs_loader_relative_container" onClick={useWebCam} style={{ margin: '10px', display: image ? 'none' : 'block' }} htmlFor="ccowebcam">
                                            <span className="cco_form_field_label_icon cco_form_field_label_icon_camera"></span>
                                            <span className="cco_form_field_label_caption">{t('PhotoManagement.PhotoUpload.Webcam')}</span>
                                        </label>
                                        
                                    }
                               {!isMobile && !image &&(     
                        <label className="" style={{ margin: '10px' }}>
                            <img src={logo} width={180} />
                        </label>
                    )}<br/>



                                </div>




                                {image && (
                                    <div className="cco_form_field_body" style={{ width: "100%", padding: '10px' }}>
                                        <div style={{ width: "100%", height: 'auto', textAlign: 'center' }}>
                                            <div style={{ maxWidth: "100%", margin: "0 auto" }}>
                                                <Cropper
                                                    ref={cropperPhotoRef}
                                                    style={{ height: '100%', width: "100%", maxWidth: "400px", textAlign: 'center' }}
                                                    zoomTo={0}
                                                    initialAspectRatio={1}
                                                    src={image}
                                                    viewMode={1}
                                                    minCropBoxHeight={300}
                                                    minCropBoxWidth={300}
                                                    aspectRatio={1}
                                                    autoCrop={true}
                                                    background={false}
                                                    responsive={true}
                                                    cropBoxResizable={false}
                                                    autoCropArea={0.8}
                                                    dragMode="move"
                                                    checkOrientation={true}
                                                    center={true}
                                                    guides={true}
                                                    movable={true}
                                                    scaleY={1}
                                                    scaleX={1}
                                                  
                                                />
                                            </div>
                                            
                                            <div className='cco_btn cco_btn_8'>
                                                <button onClick={rotateImage}>{t('PhotoManagement.PhotoUpload.Rotate')}</button>
                                                <button onClick={handleZoomIn}>{t('PhotoManagement.PhotoUpload.ZoomIn')}</button>
                                                <button onClick={handleZoomOut}>{t('PhotoManagement.PhotoUpload.ZoomOut')}</button>
                                                <button onClick={resetImage}>{t('PhotoManagement.PhotoUpload.Remove')}</button>
                                                <button onClick={getCropData}>{t('PhotoManagement.PhotoUpload.Image')}</button>
                                            </div>
                                           
                                            
                                        </div>
                                    </div>
                                )} 



                            </div>
                        </div>

                    </div>
                    {showFaceError && (
                        <div
                            className="cco_form_message cco_form_message_error"
                            style={{
                                marginBottom: 10
                            }}>
                          
                          {faceError ? faceError: t('PhotoManagement.PhotoUpload.PhotoRec')}
                        </div>
                    )}
                    {isPhotoCroped &&
                        <div className='cco_btn cco_btn_noBorder' style={{marginBottom: '10px'}}>
                            <button onClick={resetImage}>{t('PhotoManagement.PhotoUpload.Reset')}</button>
                        </div>
                    } 
                     {isMobile && (
                        <label className="" style={{ margin: '10px' }}>
                            <img src={logo} width={180} />
                        </label>
                    )}<br/>
                
                        <a href='https://www.nccco.org/photos' target='_blank' >{t('PhotoManagement.PhotoUpload.Submit')}</a>

                </div>
            </div>


            {useCam &&
                <div className="cco_form_col cco_form_col_2">
                    <div className="cco_form_wrap" style={{ padding: '0px' }}>
                        <div className="cco_form_field cco_form_field">
                            <div className="cco_form_field_body">
                                <div className="cco_form_fieldset_item " style={{ textAlign: "center" }}>
                                    <div className="cco_form_label"></div>
                                    <><div className='webCam' style={{ width: '300px', height: '200px', border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden', display: 'contents' }}>
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat='image/jpeg' />
                                    </div>
                                        <div className='cco_btn cco_btn_8'>
                                            <button onClick={captureImageFromWebcam}>{t('PhotoManagement.PhotoUpload.Capture')}</button>
                                            <button onClick={resetImage}>{t('PhotoManagement.PhotoUpload.Close')}</button>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </div >
    );
};

