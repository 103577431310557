
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuItem from './MenuItem'; // Adjust the path to MenuItem.js
import homeIcon from '../../assets/images/icons8-home-64.png';
import homeIcon2 from '../../assets/images/icons8-home-64 (1).png';
import ResourceIcon from '../../assets/images/icons8-test-50.png';
import ResourceIcon1 from '../../assets/images/icons8-test-50 (1).png';
import ShopIcon from '../../assets/images/icons8-shop-50.png';
import ShopIcon1 from '../../assets/images/icons8-shop-50 (1).png';
import ContactIcon from '../../assets/images/icons8-call-50.png';
import ContactIcon1 from '../../assets/images/icons8-call-50 (1).png';
import NewsIcon from '../../assets/images/icons8-news-50.png';
import NewIcon1 from '../../assets/images/icons8-news-50 (1).png';
import useIsMobile from '../../hooks/Helper/useIsMobile';

export const SideMenu = ({ isSidbarActive, isDashboard }) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
 

    return (
        <>
               <div className={`cco_sidebar_overlay ${isSidbarActive ? 'cco_sidebar_overlay_active' : ''}`} id="ccoSidebarOverlay" /><aside className={`cco_sidebar ${isSidbarActive ? 'cco_sidebar_active' : null}`} id="ccoSidebar">
            <div className="cco_sidebar_inner" >
                <ul className="cco_menu cco_menu_5" >
                    <li className={`cco_menu_item cco_menu_item_icon_dashboard ${isDashboard ? 'cco_menu_item_active' : ''}`}>
                        {/* <a href="/dashboard">Dashboard</a> */}
                        <Link to={'/dashboard'}>{t('sidebar.dashboardLink')}</Link>
                    </li>
                        <MenuItem
                            defaultIcon={homeIcon}
                            hoverIcon={homeIcon2}
                            link="https://nccco.org"
                            text="NCCCO.org"
                            altText="Home Icon"
                        />
                        <MenuItem
                            defaultIcon={NewsIcon}
                            hoverIcon={NewIcon1}
                            link="https://www.nccco.org/nccco/news-center/news-headlines"
                            text={t('sidebar.newsLink')}
                            altText="News Icon"
                        />
                        <MenuItem
                            defaultIcon={ResourceIcon}
                            hoverIcon={ResourceIcon1} // Adjust if you have a different hover icon
                            link="https://www.nccco.org/nccco/resources/training-resources/reference-materials"
                            text={t('sidebar.resourcesLink')}
                            altText="Resource Icon"
                        />
                        <MenuItem
                            defaultIcon={ShopIcon}
                            hoverIcon={ShopIcon1} // Adjust if you have a different hover icon
                            link="https://store.ccocert.org"
                            text={t('sidebar.storeLink')}
                            altText="Shop Icon"
                        />
                        <MenuItem
                            defaultIcon={ContactIcon}
                            hoverIcon={ContactIcon1} // Adjust if you have a different hover icon
                            link="https://www.nccco.org/nccco/contact-us"
                            text={t('sidebar.contactUsLink')}
                            altText="Contact Icon"
                        />
                    </ul>
                </div>
            </aside>
        </>
    );
};



