import * as yup from 'yup';
// import { useTranslation } from 'react-i18next';
import 'yup-phone';

// const { t } = useTranslation();
const getRequiredMessage = (field: string) => `The ${field} field is required`;

export const accountSchema = yup.object().shape({
    zip: yup.string().trim().required(getRequiredMessage('zip')),
    city: yup.string().trim().required(getRequiredMessage('city')),
    street1: yup.string().trim().required(getRequiredMessage('street1')),
    phone: yup
    .string()
    .required(getRequiredMessage('phone'))
    .matches(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, "Mobile phone must be a valid 10-digit number"),
  
  
    state: yup.string().test('select-state', getRequiredMessage('state'), value => value !== 'default'),
    country: yup.string().test('select-country', getRequiredMessage('country'), value => value !== 'default'),
});
