
import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export const useCredentialRenewalValidate = (contactCredentialId:string) => {
    return useQuery<any, Error>(
        ['CredRenValidate'],
        async () => {
            const jsonResponse = await request({
                endpoint: 'credentials/validate-contact-credential-renewal',
                data: {
                    contactCredentialId:contactCredentialId
                },
                
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled:!!contactCredentialId,
        refetchOnWindowFocus: false,  

        }
    );
};
