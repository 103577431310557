import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject } from '../types/cadtypes';

export const useCadCraneTypes = (query?: string | null) => {
    return useQuery<RootObject, Error>(['query',query], async () => {
        const jsonResponse = await request({
            endpoint: 'cad/types',
            data: {
                query: query,
            },
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    },{
    refetchOnWindowFocus: false,  
    });
};
