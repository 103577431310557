import React from 'react';

interface IBannerProps{

    showBanner:boolean,
    message:string,
    background:string;
    color?:string;
    isPhotoBanner?:boolean;
    type?:string;
    isMobile?:boolean;
}
export const NotificationBanner = ({showBanner,message,background,color,isPhotoBanner,type='',isMobile}:IBannerProps) => {

        const sidebarElement = document.getElementById("ccoSidebar") as HTMLElement;
        if(showBanner){
        sidebarElement.style.paddingTop =showBanner && type==''?"95px":isPhotoBanner && type==='ac'?'170px': '110px';
      
        }
        const isIpad =(window.innerWidth >= 768 && window.innerWidth <= 1024);
      
    return (
        showBanner ? (
            <div className="custom-overlay">
                <div className="cco_row" style={{ marginBottom: '20px',   marginTop: (
      isPhotoBanner && type === 'ac' && isMobile 
        ? '130px'  // Mobile case
        : isPhotoBanner && type === 'ac' && isIpad
        ? '85px'   // iPad case
        : isPhotoBanner && type === 'ac' 
        ? '55px'   // Default case
        : ''
    ) 
  }}>
                    <div className="cco_col">
                        <div className={`cco_text cco_text_${background}`} style={{color:color?color:''}}>
                            <div className="cco_text_inner">
                                <p>{message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (null)

    );
};