import React, { createRef, useEffect, useRef, useState } from 'react';
import { ReactCropperElement } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import Webcam from 'react-webcam';
import { useUpload } from '../../hooks/useUpload';
import { useContactPhotoRequestMutationQuery } from '../../hooks/useContactPhotoRequestMutationQuery';
import { useUser } from '../../hooks/useUser';
import { IContactPhoto } from '../../types/profilephoto';
import { BlockUI, Loader, } from '../../components/Loader';
import { Root } from '../../types/dashboard';
import { PhotoUpload } from './PhotoUpload';
import { PhotoIDUpload } from './PhotoIDUpload';
import { useContactPhotoRequest } from '../../hooks/useContactPhotoRequest';
import { getContactId, getDate, getExtByMimeType, getUserBasicData } from '../../tools/utils';
import { AccessControl, useGlobalContext } from '../../hooks/Helper/GlobalContext';
// import { useFaceDetection } from '@app/hooks/useFaceDetection';
import { faceRequest } from '../../services/faceRequest';
import { useTranslation } from 'react-i18next';
export const PhotoManagement = () => {

    const { t } = useTranslation();
    const contactId = getContactId();
    const photoQuery = useContactPhotoRequest({ contactId: contactId });
    const photoData = photoQuery.data?.data?.Data;
    console.log(photoData)
    const userData =getUserBasicData();
    const [showLoading, setLoading] = useState(false);
    const [showFaceError, setFaceError] = useState(false);
    const [isPhotoCroped, setisPhotoCroped] = useState(false);
    const [faceError, setError] = useState('');
    const [isPhotoIDCroped, setisPhotoIDCroped] = useState(false);
    const [image, setImage] = useState('');
    const [imagePhotoID, setImagePhotoID] = useState('');
    const [PhotoBlob, setPhotoBlob] = useState<null | Blob>(null);
    const [PhotoIDBlob, setPhotoIDBlob] = useState<null | Blob>(null);
    const cropperPhotoRef = createRef<ReactCropperElement>();
    const cropperPhotoIdRef = createRef<ReactCropperElement>();
    const {CandidateAccessControl}=useGlobalContext();

    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];

        // Check if the file is an image based on the file extension
        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const fileExtension = file.name.split('.').pop()?.toLowerCase(); // Add more image types if needed
        if (allowedImageTypes.includes(file.type)&& fileExtension !== 'jfif') {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result as any);
                setisPhotoCroped(false);
                setPhotoBlob(null);
                setCam(false);
            };
            reader.readAsDataURL(file);

        } else {
            // The file is not an image; you can show an error message or take appropriate action
            alert(t('PhotoManagement.PhotoManagement.error'));

        }
    };

    const onChangePhotoID = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
    
        const file = files[0];
    
        // Check if the file is an image based on the file extension and MIME type
        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
    
        if (allowedImageTypes.includes(file.type) && fileExtension !== 'jfif') {
            const reader = new FileReader();
            reader.onload = () => {
                setImagePhotoID(reader.result as any);
                setisPhotoIDCroped(false);
                setPhotoIDBlob(null);
                setCamPhotoID(false);
            };
            reader.readAsDataURL(file);
        } else {
            // The file is not an image or has an unsupported extension
            alert(t('PhotoManagement.PhotoManagement.error'));
        }
    };
    
    const getCropData = () => {
        if (typeof cropperPhotoRef.current?.cropper !== "undefined") {
            // Get the data URL from the Cropper.js instance
            const dataURL = cropperPhotoRef.current?.cropper.getCroppedCanvas().toDataURL('image/jpeg',0.9);

            // Convert the data URL to a Blob
            fetch(dataURL)
                .then((res) => res.blob())
                .then(async (blob) => {
                    // Now you have the Blob object
                    console.log(blob);
                    setImage('')
                    setPhotoBlob(blob)
                    setisPhotoCroped(true)
                    setCam(false)
                    console.log(PhotoBlob)
                })
                .catch((error) => {
                    // Handle any errors that may occur during the conversion
                    console.error("Error converting data URL to Blob:", error);
                });
        }
    };

    const getCropDataPhotoID = () => {
        if (typeof cropperPhotoIdRef.current?.cropper !== "undefined") {
            // Get the data URL from the Cropper.js instance
            const dataURL = cropperPhotoIdRef.current?.cropper.getCroppedCanvas().toDataURL('image/jpeg',0.9);

            // Convert the data URL to a Blob
            fetch(dataURL)
                .then((res) => res.blob())
                .then((blob) => {
                    // Now you have the Blob object
                    console.log(blob);
                    // You can also set the Blob in state if needed

                    setImagePhotoID('')
                    setPhotoIDBlob(blob)
                    setisPhotoIDCroped(true)
                    setCamPhotoID(false)
                    console.log(PhotoBlob)


                })
                .catch((error) => {
                    // Handle any errors that may occur during the conversion
                    console.error("Error converting data URL to Blob:", error);
                });
        }
    };

    const resetImage = () => {

        setImage('');
        setPhotoBlob(null)
        setisPhotoCroped(false)
        setCam(false)
        setFaceError(false)
    };

    const resetImagePhotoID = () => {
        setImagePhotoID('');
        setPhotoIDBlob(null)
        setisPhotoIDCroped(false)
        setCamPhotoID(false)
    };

    const handleZoomIn = () => {

        const cropper = cropperPhotoRef?.current?.cropper;
        cropper?.zoom(0.1);



    };

    const handleZoomOut = () => {
        const cropper = cropperPhotoRef?.current?.cropper;
        cropper?.zoom(-0.1);

    };

    const handleZoomInPhotoID = () => {

        const cropper = cropperPhotoIdRef?.current?.cropper;
        cropper?.zoom(0.1);


    };

    const handleZoomOutPhotoID = () => {

        const cropper = cropperPhotoIdRef?.current?.cropper;
        cropper?.zoom(-0.1);

    };

    //#region webcam
    const webcamRef = useRef<Webcam>(null);
    const webcamPhotoIDRef = useRef<Webcam>(null);
    const [useCam, setCam] = useState(false);
    const [useCamPhotoID, setCamPhotoID] = useState(false);

    const captureImageFromWebcam = () => {
        debugger
        const imageSrc = webcamRef?.current?.getScreenshot();
        setImage(imageSrc as string);
        setCam(false)
    };

    const captureImageFromWebcamPhotoID = () => {

        const imageSrc = webcamPhotoIDRef?.current?.getScreenshot();
        setImagePhotoID(imageSrc as string);

        setCamPhotoID(false)
    };

    const useWebCam = () => {
        setPhotoBlob(null)
        setisPhotoCroped(false)
        setImage('')
        setCam(true);
    }

    const useWebCamPhotoID = () => {
        setPhotoIDBlob(null)
        setisPhotoIDCroped(false)
        setImagePhotoID('')
        setCamPhotoID(true);
    }
    //#endregion 

    //#region Request

    const PhotoMutation = useContactPhotoRequestMutationQuery();
    const uploadFile = useUpload();

    const submitHandler = () => {
        setLoading(true);
        const upload = [];
        const uploadedFiles: any[] = [];
        const submissionDate = getDate('MMDDYY');
        const data: IContactPhoto = {
            ContactId: contactId,
            Name: `${userData.Last}-${userData.CandidateId}-${submissionDate}`,
            PhotoIdLicenceURL: PhotoIDBlob,
            PhotoURL: PhotoBlob,
        };



        if (data.PhotoURL instanceof Blob) {
            const extension = getExtByMimeType(data.PhotoURL.type);
            const fileName = `PhotoURL.${extension}`;
            upload.push({
                name: 'PhotoURL',
                file: new File([data.PhotoURL], fileName, { type: data.PhotoURL.type }),
                
            });
        }

        if (data.PhotoIdLicenceURL instanceof Blob) {
            const extension = getExtByMimeType(data.PhotoIdLicenceURL.type);
            const fileName = `PhotoIdLicenceURL.${extension}`;
            upload.push({
                name: 'PhotoIdLicenceURL',
                file: new File([data.PhotoIdLicenceURL], fileName, { type: data.PhotoIdLicenceURL.type }),
            });
        }

        if (upload.length) {
            upload.forEach(file => {
                const formData = new FormData();
                formData.append('file', file.file);
                uploadedFiles.push(
                    uploadFile.mutateAsync({
                        body: formData,
                        type: file.file.type.includes('image') ? 'image' : 'file',
                        isPrivate: file.name === 'PhotoIdLicenceURL' ? true : false,
                    })
                );
            });
        }

        Promise.all(uploadedFiles)
            .then(async files => {
                if (2 === files.length) {
                    if (data.PhotoURL) {
                        data.PhotoURL = files[0].data.Data.FileName;
                    }
                    if (data.PhotoIdLicenceURL) {
                        data.PhotoIdLicenceURL = files[1].data.Data.FileName;
                    }
                }
                // console.log(data);
                // FaceMutation.mutateAsync({ imageUrl: data.PhotoURL }).then(res => {
                //     const resData = res.data?.Data as IFaceData[];

                //     if (!resData.length) {
                //         setFaceError(true);
                //         setLoading(false)
                
                //     }
                //     else
                //     {
                //     PhotoMutation.mutateAsync({ body: data, type: 'POST' })
                //         .then(res => {
                //             if (res?.data?.ApiCode === 200 && res.success === true) {
                //                 window.location.href = '/dashboard/';
                //             }
                //         })
                //         .finally(() => {
                //             setLoading(false);
                //         });
                //     }
                // }).catch(()=>{
                //     setLoading(false);
                // });
                const jsonResponse = await faceRequest({endpoint:'face/v1.0/detect',data:{url:data.PhotoURL}});
                if(jsonResponse.success ===true && jsonResponse.status===200 && jsonResponse.data.length>0){                    
                    PhotoMutation.mutateAsync({ body: data, type: 'POST', }).then((res) => {
                        if (res?.data?.ApiCode === 200 && res.success === true) {
                             window.location.href = "/dashboard/";
                        }
                    }
    
                    ).finally(() => {
                        setLoading(false);
    
                    });
                }
                else
                {
                    setLoading(false);
                    setFaceError(true);
                    setError(jsonResponse?.data?.error?.message)
                    PhotoMutation.reset()
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };
    //endregion

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767); // Adjust the breakpoint as needed
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767); // Adjust the breakpoint as needed
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handlerotateImage = () => {
        if (cropperPhotoRef?.current?.cropper) {
            cropperPhotoRef.current.cropper.rotate(90); // Rotate 90 degrees clockwise
        }
    };

    const handleRotatePhotoID = () => {
        if (cropperPhotoIdRef?.current?.cropper) {
            cropperPhotoIdRef.current.cropper.rotate(90); // Rotate 90 degrees clockwise
        }
    };




    return (
        <div id="main-content">
            <div className="cco_content_area cco_content_area_0">
                <article className="react-container">
                    <div className="cco_section cco_section_3">
                        <div className="cco_row cco_row_8">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong>
                                            <a href="/dashboard">{t('PhotoManagement.PhotoManagement.Dashboard')}</a>
                                        </strong>{' '}
                                        {'>'} {t('PhotoManagement.PhotoManagement.PhotoManagement')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cco_row" style={{ marginBottom: "20px" }}>
                            <div className="cco_col">
                                {photoQuery.isLoading ? (
                                    <Loader />
                                ) : photoQuery.error ? (
                                    <p>{photoQuery.error.message}</p>
                                ) : photoData && (photoData?.length > 0 || CandidateAccessControl===AccessControl.Suspended || CandidateAccessControl===AccessControl.Locked) ?(
                                    <div className="cco_text cco_text_12">
                                        <div className="cco_text_inner">
                                        {photoData?.length > 0 && (CandidateAccessControl !== AccessControl.Locked && CandidateAccessControl !== AccessControl.Suspended) && <p>{t('PhotoManagement.PhotoManagement.Request')}</p>}
                                        {(CandidateAccessControl===AccessControl.Locked || CandidateAccessControl===AccessControl.Suspended) && 
                                <p>{t('PhotoManagement.PhotoManagement.Record')} </p>
                                }
                                            {/* {(photoData?.Status === 'Rejected') && (
                                        <p>Contact Photo Request is rejected. Please <a href="javascript:void(0)" style={{color:'white'}} >Submit again</a> </p>
                                    )} */}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {photoQuery.isFetched && !photoQuery.isError && (!photoData || photoData.length <= 0) && CandidateAccessControl !==AccessControl.Locked && CandidateAccessControl !==AccessControl.Suspended && (

                            <><div className="cco_row cco_row_4">
                                <div className="cco_col">
                                    <div className="cco_text cco_text_0">
                                        <div className="cco_text_inner">
                                            <strong>{t('PhotoManagement.PhotoManagement.Submission')}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div><>

                                    <PhotoUpload
                                        PhotoBlob={PhotoBlob}
                                        captureImageFromWebcam={captureImageFromWebcam}
                                        cropperPhotoRef={cropperPhotoRef}
                                        getCropData={getCropData}
                                        handleZoomIn={handleZoomIn}
                                        handleZoomOut={handleZoomOut}
                                        image={image}
                                        isMobile={isMobile}
                                        isPhotoCroped={isPhotoCroped}
                                        onChange={onChange}
                                        resetImage={resetImage}
                                        useCam={useCam}
                                        useWebCam={useWebCam}
                                        webcamRef={webcamRef}
                                        showFaceError={showFaceError}
                                        key={2} 
                                        rotateImage={handlerotateImage}
                                        faceError={faceError}
                                        />
                                        
                                        <PhotoIDUpload
                                        PhotoIDBlob={PhotoIDBlob}
                                        captureImageFromWebcamPhotoID={captureImageFromWebcamPhotoID}
                                        cropperPhotoIdRef={cropperPhotoIdRef}
                                        getCropDataPhotoID={getCropDataPhotoID}
                                        handleZoomInPhotoID={handleZoomInPhotoID}
                                        handleZoomOutPhotoID={handleZoomOutPhotoID}
                                        imagePhotoID={imagePhotoID}
                                        isMobile={isMobile}
                                        isPhotoIDCroped={isPhotoIDCroped}
                                        onChangePhotoID={onChangePhotoID}
                                        resetImagePhotoID={resetImagePhotoID}
                                        useCamPhotoID={useCamPhotoID}
                                        useWebCamPhotoID={useWebCamPhotoID}
                                        webcamPhotoIDRef={webcamPhotoIDRef}
                                        key={2} 
                                        rotateImageID={handleRotatePhotoID}
                                        /><div className="cco_form_row">
                                        <div className="cco_form_col">
                                            {!PhotoMutation.isLoading && (
                                                <>
                                                    {PhotoMutation.data?.success && (
                                                        <div
                                                            className="cco_form_message cco_form_message_success"
                                                            style={{
                                                                fontSize: 16,
                                                                marginBottom: 20
                                                            }}>
                                                            <strong>
                                                                {t('PhotoManagement.PhotoManagement.Success')}
                                                            </strong>
                                                        </div>
                                                    )}

                                                    {PhotoMutation.error && (
                                                        <div
                                                            className="cco_form_message cco_form_message_error"
                                                            style={{
                                                                marginBottom: 20
                                                            }}>
                                                            {(PhotoMutation.error as Error).message}
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {/* <button className="cco_form_submit" type={'submit'}>
{isSubmitting ? 'Saving...' : 'Save Changes'}
</button> */}



                                            <ul className="cco_menu cco_menu_7">
                                                {isPhotoCroped && isPhotoIDCroped &&
                                                    <li className="cco_menu_item">
                                                        <div className='cco_btn cco_btn_mybtn'>
                                                            <button onClick={submitHandler} className="cco_btn cco_btn_mybtn" type={'submit'}>
                                                                {PhotoMutation.isLoading ? t('PhotoManagement.PhotoManagement.Submitting') : t('PhotoManagement.PhotoManagement.Submit')}
                                                            </button>
                                                        </div>

                                                    </li>}

                                                {!PhotoMutation.isLoading && PhotoMutation.data?.success && (
                                                    <li className="cco_menu_item">
                                                        <a href="/dashboard">{t('PhotoManagement.PhotoManagement.Return')}</a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div><BlockUI  
                                    title={t('PhotoManagement.PhotoManagement.Waiting')} blocking={showLoading || PhotoMutation.isSuccess}></BlockUI></></>

                        )}
                    </div>
                </article>
            </div>
        </div>
    );
};

