import type { RootObject } from '../types/prac';

import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { getContactId } from '../tools/utils';

export const usePRACTestSites = () => {
    return useQuery<RootObject, Error>(['tsc_prac'], async () => {
        const jsonResponse = await request({
            endpoint: 'locations/get-pe-ts',
            data: {
                tscId:await getContactId(),
            },
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    },{
    refetchOnWindowFocus: false,  

    });
};
