import * as yup from 'yup';

import 'yup-phone';

const getRequiredMessage = (field: string) => `The ${field} field is required`;
const checkMaxAllowedInMb = (value: number, maxValue: number = 1000000) => !(value / maxValue > 1);

export const singlePractical = yup.object().shape({
    email: yup.string().email('Invalid email').required(getRequiredMessage('email')),
    fullName: yup.string().required(getRequiredMessage('full name')),
    // phoneNumber: yup.string().phone('US', true, 'Invalid phone number').required(getRequiredMessage('phone number')),
    phoneNumber: yup.string().required(getRequiredMessage('phone number')),

    emailConfirmation: yup
        .string()
        .email('Invalid email')
        .required(getRequiredMessage('email confirmation'))
        .oneOf([yup.ref('email')], 'Emails must match'),
    termsAndConditions: yup
        .mixed()
        .required(getRequiredMessage('terms & conditions'))
        .test('fileSize', 'The file is too large (Max 10 MB)', value => {
            if (value) {
                return checkMaxAllowedInMb(value.size, 10000000);
            }

            return true;
        }),
    // isASecureFacility: yup
    //     .string()
    //     .test('select-secure-facility', getRequiredMessage('secure facility'), value => value !== 'default'),
    // numberOfCandidateTestingStations: yup.string().required(getRequiredMessage('number of candidate testing stations')),
});
