import type { IValuesPrac } from '../../../containers/NewPracLocation/valuesTypes';

import classNames from 'classnames';

import { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { screenedValuesPrac } from '../../../tools/constants';
import { useCountries } from '../../../hooks/useCountries';
import { useStates } from '../../../hooks/useStates';
import { useTranslation } from 'react-i18next';

export const LocationInformation = ({
    handler,
    editable = true,
    single,
}: {
    handler?: (step: number) => void;
    editable?: boolean;
    single?: boolean;
}) => {
    const { values, errors, touched, submitCount } = useFormikContext<IValuesPrac>();
    const { t } = useTranslation();

    const countriesQuery = useCountries('default' !== values.country);
    const statesQuery = useStates('default' !== values.country ? values.country : undefined);

    const statesData = useMemo(
        () => (statesQuery.data?.data.Data || []).find(state => state.ProvinceId === values.state),
        [statesQuery.data?.data.Data, values.state]
    );
    const countriesData = useMemo(
        () => (countriesQuery.data?.data.Data || []).find(country => country.CountryId === values.country),
        [countriesQuery.data?.data.Data, values.country]
    );

    const [isExpanded, setIsExpanded] = useState(single);

    const isFormCompleted = useMemo(
        () => screenedValuesPrac[0].every(field => !errors[field] && touched[field]),
        [errors, touched]
    );

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded,
            })}
        >
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>1. {t('SummaryPracSite.LocationInformation.LocationInformation')}</h3>

                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted,
                            })}
                        >
                            {isFormCompleted
                                ? t('SummaryPracSite.LocationInformation.com')
                                : submitCount < 1
                                ? t('SummaryPracSite.LocationInformation.inprog')
                                : t('SummaryPracSite.LocationInformation.note')}
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    className="cco_collapse_toggle"
                    onClick={() => setIsExpanded(prevState => !prevState)}
                >
                    <span className="cco_collapse_toggle_text">{t('SummaryPracSite.LocationInformation.View')}</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.CompanyName')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.companyName || t('SummaryPracSite.LocationInformation.Notcompleted')}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.Street1')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.street1 || t('SummaryPracSite.LocationInformation.Notcompleted')}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.Street2')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.street2 || t('SummaryPracSite.LocationInformation.Notcompleted')}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.City')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.city || t('SummaryPracSite.LocationInformation.Notcompleted')}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.Zip')}</div>
                        <div className="cco_blurb_value">
                            <strong>{values.zip || t('SummaryPracSite.LocationInformation.Notcompleted')}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.State')}</div>
                        <div className="cco_blurb_value">
                            <strong>
                                {statesQuery.data ? statesData?.ProvinceName || t('SummaryPracSite.LocationInformation.Notcompleted'): t('SummaryPracSite.LocationInformation.waiting')}
                            </strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.Country')}</div>
                        <div className="cco_blurb_value">
                            <strong>
                                {countriesQuery.data ? countriesData?.CountryName || t('SummaryPracSite.LocationInformation.Notcompleted') : t('SummaryPracSite.LocationInformation.waiting')}
                            </strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.fac')}</div>
                        <div className="cco_blurb_value">
                            <strong>
                                {'default' !== values.isASecureFacility
                                    ? 'yes' === values.isASecureFacility
                                        ? t('SummaryPracSite.LocationInformation.Yes')
                                        : t('SummaryPracSite.LocationInformation.No')
                                    : t('SummaryPracSite.LocationInformation.Notcompleted')}
                            </strong>
                        </div>
                    </div>

                    {'yes' === values.isASecureFacility && (
                        <div className="cco_blurb cco_blurb_1">
                            <div className="cco_blurb_label">{t('SummaryPracSite.LocationInformation.sec')}</div>
                            <div className="cco_blurb_value">
                                <strong>{values.additionalSecurity ? t('SummaryPracSite.LocationInformation.com') : t('SummaryPracSite.LocationInformation.Notcompleted')}</strong>
                            </div>
                        </div>
                    )}

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(0);
                                    e.preventDefault();
                                }}
                            >
                                {t('SummaryPracSite.LocationInformation.Edit')}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
