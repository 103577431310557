import { getToken } from "../tools/utils";

type IGenericObject = {
    [key: string]: any;
};

interface IRequestOptions {
    endpoint: string;
    signal?: AbortSignal;
    data?: IGenericObject;
}

export const generatePDFRequest = async ({ endpoint, data}: IRequestOptions) => {
    const tokenId=await getToken();
   
    
    const response = await fetch(`${'https://ccoapi.azure-api.net/webapi-nccco-org/v1/'}${endpoint}?${data && `${new URLSearchParams(data)}`}`,
    {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${tokenId}`,
            apiKey: 'wG$Jt8#y56!',
        },
    }
);

    
    const success = response.ok;


    return {
        success,
        data: await response,
        status:response.status
    };
};
