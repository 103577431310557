import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject } from '../types/prerequisite';

export const usePrerequisiteCredential = (credentialId: string, language?: string | null) => {
    return useQuery<RootObject, Error>(
        ['validate-pre-req', credentialId, language], // Include language in the query key
        async () => {
            const jsonResponse = await request({
                endpoint: 'credentials/validate-pre-req-contact-credential',
                data: {
                    credentialId: credentialId,
                    language: language, // Pass the language if provided
                },
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled: !!credentialId, // Only enable query if credentialId is available
            refetchOnWindowFocus: false,
        }
    );
};

