import { allowedFileTypes } from '../tools/utils';
import * as yup from 'yup';

const getRequiredMessage = (field: string) => `The ${field} field is required`;
const checkMaxAllowedInMb = (value: number, maxValue: number = 1000000) => !(value / maxValue > 1);
export const CADSchema = yup.object().shape({
    make: yup.string().trim().required(getRequiredMessage('Make')),
    model: yup.string().trim().required(getRequiredMessage('Model')),
    serialNumber: yup.string().trim().required(getRequiredMessage('SerialNumber')),
    maxRelatedCapacity: yup.number().required(getRequiredMessage('maxRelatedCapacity')).test('not-zero', 'Value must not be zero', value => value !== 0),
    boomLength: yup.number().required(getRequiredMessage('Boomlength')).typeError('Boom Length must be an integer')
    .when('craneType', {
        is: (craneType: string) => craneType === 'TSS' || craneType === 'TLL',
        then:yup.number().integer().required(getRequiredMessage('Boomlength')).typeError('Boom Length must be an integer')
    })
    // .when('maxRelatedCapacity', (maxRelatedCapacity:number, schema) => {
    //   return schema.test({
    //     name: 'boomLengthValidation',
    //     test: function() {
    //       const boomLength = this.parent.boomLength;
    //       if (maxRelatedCapacity < 50) {
    //         return boomLength >= 70 && boomLength <= 90;
    //       } else if (maxRelatedCapacity > 50) {
    //         return boomLength >= 110 && boomLength <= 130;
    //       }
    //       return true; 
    //     },
    //     message: `Boom length should be ${maxRelatedCapacity < 50 ? 'between 70-90(ft)' : 'between 110-130(ft)'}`,
    //   });
    // }),
    ,
    isBoomTruck: yup.string()
    .when('craneType', {
      is: (craneType: string) => craneType === 'TSS' || craneType === 'TLL',
      then: yup.string().test('select-boom', getRequiredMessage('Is boomtruck'), value => value !== 'default'),
    }),


    workingArea: yup.string()
    .when('craneType',{
      is:(cranType:string)=>cranType==='TSS' || cranType ==='TLL',
      then:yup.string().test('select-workingArea',getRequiredMessage('Working area'),value => value !== 'default')
    }),
    cranePosition: yup.string()
    .when('craneType',{
      is:(cranType:string)=>cranType==='TSS' || cranType ==='TLL',
      then:yup.string().test('select-cranePosition',getRequiredMessage('cranePosition'),value => value !== 'default')
    }),
    truckBedLength: yup.number()
    .when('craneType',{
      is:(cranType:string)=>cranType==='TSS' || cranType ==='TLL',
      then:yup.number().integer().required(getRequiredMessage('truckBedLength')).typeError('Truck Bed Length can only be a number')
    }),

    frontOutrigger: yup.mixed().optional(),
    backOutrigger: yup.mixed().optional(),
    ropeSizeType: yup.mixed().optional(),
    allowableLine: yup.number().integer().required(getRequiredMessage('allowableLine')),
    diameterTestWeight: yup.number().required(getRequiredMessage('diameterTestWeight')),

    loadChart:yup.mixed().required(getRequiredMessage('load Chart'))
    .test('fileSize', 'The file is too large (Max 5 MB)', (value:any) => {
        if (value) {
            return checkMaxAllowedInMb(value.size, 5000000);
        }

        return true;
    }).test('fileType', 'File type not supported.', (value:any) => {
        if (allowedFileTypes.indexOf(value?.type) === -1) {
       return false; 
   }
   return true;
}),
    linePullChart: yup
    .mixed()
    .required(getRequiredMessage('line pull chart'))
    .test('fileSize', 'The file is too large (Max 5 MB)', (value:any) => {
        if (value) {
            return checkMaxAllowedInMb(value.size, 5000000);
        }

        return true;
    }).test('fileType', 'File type not supported.', (value:any) => {
        if (allowedFileTypes.indexOf(value?.type) === -1) {
       return false; 
   }
   return true;
}),
    rangeDiagram: yup
    .mixed()
    .required(getRequiredMessage('range diagram'))
    .test('fileSize', 'The file is too large (Max 5 MB)', (value:any) => {
        if (value) {
            return checkMaxAllowedInMb(value.size, 5000000);
        }

        return true;
    }).test('fileType', 'File type not supported.', (value:any) => {
        if (allowedFileTypes.indexOf(value?.type) === -1) {
       return false; 
   }
   return true;
}),
    Zip: yup.string().trim().required(getRequiredMessage('Zip')),
    City: yup.string().trim().required(getRequiredMessage('City')),
    FirstName: yup.string().trim().required(getRequiredMessage('First Name')),
    LastName: yup.string().trim().required(getRequiredMessage('Last Name')),
    Address: yup.string().trim().required(getRequiredMessage('Address')),
    State: yup.string().trim().required(getRequiredMessage('State')),
    BillToEmail: yup.string().trim().email('Invalid email').required(getRequiredMessage('Email'))  ,
    BillingPhone: yup.string().trim().required(getRequiredMessage('phone')),
    BillingCountry: yup.string().trim().required(getRequiredMessage('country')),
})
