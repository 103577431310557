
import type { CADData } from '../../types/customcad';

interface TSCProps {
    item: CADData;
    type?: 'Pending' | 'Approved' | 'Completed' | 'Expired' | 'PendingPayment';
}

export const CAD = ({ item,type }: TSCProps) => {
    return (
        <li className='cco_accordion_item active'>
            <div className="cco_accordion_item_body">

                    <table className="cco_table cco_table_1">
                        <tbody className="cco_table_body">
                            <tr className="cco_table_row">
                                <td className="cco_table_cell cco_table_cell_medium">
                                    <div className="cco_blurb cco_blurb_0">
                                        <div className="cco_blurb_label">CAD Sub type:</div>
                                        <div className="cco_blurb_value">
                                            <strong>{item.CADSubTypeName}</strong>
                                        </div>
                                    </div>
                                    <div className="cco_blurb cco_blurb_0">
                                        <div className="cco_blurb_label">Make:</div>
                                        <div className="cco_blurb_value">
                                            <strong>
                                                {item.Make}
                                            </strong>
                                        </div>
                                    </div>
                                </td>

                                <td className="cco_table_cell cco_table_cell_small">
                                    <div className="cco_blurb cco_blurb_align_center cco_blurb_0"></div>
                                    <div className="cco_blurb cco_blurb_align_center cco_blurb_0"></div>
                                </td>
                                <td className="cco_table_cell cco_table_cell_small">
                                    <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                        <div className="cco_blurb_label">Model:</div>
                                        <div className="cco_blurb_value">
                                            <strong>{item.Model}</strong>
                                        </div>
                                    </div>
                                 
                                        <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                            <div className="cco_blurb_label">S/N:</div>
                                            <div className="cco_blurb_value">
                                                <strong>{item.SerialNumber}</strong>
                                            </div>
                                        </div>
                                    
                                </td>
                            </tr>
                            {type ==='Completed' && item.CADCompletedURL && 
                            <tr className="cco_table_row">
                            <td className="cco_table_cell cco_table_cell_small">
                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                    <div className='cco_btn cco_btn_3'>
                                    <a href={item.CADCompletedURL} >View Custom CAD</a> 
                                    </div>
                                </div>
                            </td>
                        </tr>
                            }
                            
                        </tbody>
                    </table>
                </div>
          
        </li>
    );
};