import { Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

export const Attestation = () => {
    const { t } = useTranslation();
    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading">6. {t('NewLocation.Attestation.Attestation')}</h2>

                <div className="cco_form_wrap">
                    <div className="cco_text cco_text_8">
                        <div className="cco_text_inner">{t('NewLocation.Attestation.Req')}</div>
                    </div>

                    <ul className="cco_form_fieldset">
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoEntry" type="checkbox" name="attestation" value="entry" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>{t('NewLocation.Attestation.entrys')}
                            </label>
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoProctor" type="checkbox" name="attestation" value="proctor" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                             {t('NewLocation.Attestation.proctors')}
                            </label>
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoStations" type="checkbox" name="attestation" value="stations" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                               { t('NewLocation.Attestation.station')}
                            </label>
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoParking" type="checkbox" name="attestation" value="parking" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                                {t('NewLocation.Attestation.parking')}
                            </label>
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoRestroom" type="checkbox" name="attestation" value="restroom" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                               {t('NewLocation.Attestation.restroom')}
                            </label>
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoequipment" type="checkbox" name="attestation" value="equipment" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                               {t('NewLocation.Attestation.equipment')}
                            </label>
                        </li>
                    </ul>

                    <ErrorMessage component={'div'} name={'attestation'} className="cco_form_message cco_form_message_error" />
                </div>
            </div>
        </div>
    );
};
