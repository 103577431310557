import { useMutation } from '@tanstack/react-query';
import { request } from '../services/request';

export const useCreateCredentials = () => {
    return useMutation(async (data: any) => {
        const jsonResponse = await request({
            data,
            method: 'POST',
            endpoint: 'credentials/create-contact-credential',
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};

