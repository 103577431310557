import { useState, useEffect } from 'react';

const usePaymentTimer = (startTimer: boolean) => {
    const [timeRemaining, setTimeRemaining] = useState(15 * 60);
    const [isStartTimer, setStartTimer] = useState(false);


    useEffect(() => {
        let timerId: any;

        if (timeRemaining > 0 && startTimer) {
            setStartTimer(true);
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [timeRemaining]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        if (seconds > 0) {
            return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
                .toString()
                .padStart(2, '0')}`;
        }
        else {
            if (isStartTimer) {
                setStartTimer(false)
                window.location.reload();
                return 0;
            }
        }

    };

    return { timeRemaining, formatTime };
};

export { usePaymentTimer }
