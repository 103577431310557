import type { IValuesPrac } from '../../containers/NewPracLocation/valuesTypes';
import image1 from "../../assets/images/TFP_Sample.jpg"

import { ErrorMessage, Field, useFormikContext } from 'formik';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';


export const LocationDoorSign = () => {
    const { t } = useTranslation();
    const { values, errors, setFieldTouched, setFieldValue, touched } = useFormikContext<IValuesPrac>();
    const [fileError, setFileError] = useState<string | null>(null);
    const [sitePlanError, setSitePlanError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0] : null;

        if (!file) return;

        if (file.name.toLowerCase().endsWith('.jfif')) {
            setError('File type not supported.');
            setFieldValue(fieldName, null); // Reset field value
            setFieldTouched(fieldName, false); // Prevent validation error
        } else {
            setError(null); // Clear error if file is valid
            setFieldTouched(fieldName, true);
            setFieldValue(fieldName, file);
        }
    };

    return (
        <div className="cco_form_row">
            <div className="cco_form_col cco_form_col_1">
                <h2 className="cco_form_heading">2. {t('NewPracLocation.locationDoorSign.photo')} </h2>

                <div className="cco_form_wrap">
                    <div className="cco_form_fieldset cco_form_fieldset_2">
                        <div className="cco_form_fieldset_item">
                            <label htmlFor="ccoDoorSign">
                            {t('NewPracLocation.locationDoorSign.upphoto')} <small>{t('NewPracLocation.locationDoorSign.mb')}</small>
                            </label>

                            <div className="cco_form_field cco_form_field_0">
                                <div className="cco_form_field_body">
                                    <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoDoorSign">
                                        {(values.doorSign instanceof File && values.doorSign.type.includes('image')) ||
                                        ('string' === typeof values.doorSign && values.doorSign && !values.doorSign.includes('.pdf')) ? (
                                            <>
                                                <img
                                                    src={values.doorSign instanceof File ? URL.createObjectURL(values.doorSign) : values.doorSign}
                                                    onLoad={e => {
                                                        const src = e.currentTarget.src;

                                                        if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                    }}
                                                    alt=""
                                                />
                                                <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">{t('NewPracLocation.locationDoorSign.upload')}</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="cco_form_field_label_icon cco_form_field_label_icon_camera"></span>
                                                <span className="cco_form_field_label_caption">{t('NewPracLocation.locationDoorSign.upload')}</span>
                                            </>
                                        )}
                                    </label>

                                    <Field
                                        type={'file'}
                                        name={'doorSign'}
                                        value={undefined}
                                        id={'ccoDoorSign'}
                                        className={'cco_form_field_input'}
                                        accept={'.pdf, image/png, image/jpeg'}
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e, 'doorSign', setFileError)}/>
                                </div>

                                <div className="cco_form_field_aside">
                                {fileError && (
                                        <div className="cco_form_message cco_form_message_has_icon cco_form_message_error">
                                            {fileError}
                                        </div>
                                    )}
                                    {values.doorSign && !errors.doorSign && touched.doorSign && (
                                        <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                            {values.doorSign instanceof File ? values.doorSign.name : null /* values.doorSign */}
                                        </div>
                                    )}

                                    <ErrorMessage
                                        name={'doorSign'}
                                        component={'div'}
                                        className="cco_form_message cco_form_message_has_icon cco_form_message_error"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="cco_form_fieldset_item">
                            <div className="cco_form_label">{t('NewPracLocation.locationDoorSign.sample')}</div>

                            <img className="cco_form_img" src={image1} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="cco_form_col cco_form_col_2">
                <h2 className="cco_form_heading">3. {t('NewPracLocation.locationDoorSign.CourseArea')}</h2>

                <div className="cco_form_wrap">
                    <label htmlFor="ccoSitePlan">
                    {t('NewPracLocation.locationDoorSign.uploadphoto')} <small>{t('NewPracLocation.locationDoorSign.mb')}</small>
                    </label>

                    <div className="cco_form_field cco_form_field_0">
                        <div className="cco_form_field_body">
                            <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoSitePlan">
                                {(values.sitePlan instanceof File && values.sitePlan.type.includes('image')) ||
                                ('string' === typeof values.sitePlan && values.sitePlan && !values.sitePlan.includes('.pdf')) ? (
                                    <>
                                        <img
                                            src={values.sitePlan instanceof File ? URL.createObjectURL(values.sitePlan) : values.sitePlan}
                                            onLoad={e => {
                                                const src = e.currentTarget.src;

                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                            }}
                                            alt=""
                                        />
                                        <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">{t('NewPracLocation.locationDoorSign.upload')}</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                        <span className="cco_form_field_label_caption">{t('NewPracLocation.locationDoorSign.upload')}</span>
                                    </>
                                )}
                            </label>

                            <Field
                                type={'file'}
                                value={undefined}
                                name={'sitePlan'}
                                id={'ccoSitePlan'}
                                className={'cco_form_field_input'}
                                accept={'.pdf, image/png, image/jpeg'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e, 'sitePlan', setSitePlanError)}
                            />
                        </div>

                        <div className="cco_form_field_aside">
                        {sitePlanError && (
                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_error">
                                    {sitePlanError}
                                </div>
                            )}
                            {values.sitePlan && !errors.sitePlan && touched.sitePlan && (
                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                    {values.sitePlan instanceof File ? values.sitePlan.name : null /* values.sitePlan */}
                                </div>
                            )}

                            <ErrorMessage name={'sitePlan'} component={'div'} className="cco_form_message cco_form_message_has_icon cco_form_message_error" />
                        </div>
                    </div>

                    {/* <div className="cco_form_btn cco_form_btn_2">
                        <a href={'http://www.nccco.org/praclayout'} target="_blank">
                            Download Practical Site Plan Form
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
