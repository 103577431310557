import { useQuery } from '@tanstack/react-query';
import { RootObject } from '../types/allcredential';
import { request } from '../services/request';
export const useGroupCredentials = (cgId?: string | null) => {
    return useQuery<RootObject, Error>(['credentialGroupId',cgId], async () => {
        const jsonResponse = await request({
            endpoint: 'credentials/credentials-by-group',
            data: {
                credentialGroupId: cgId,
                
            },
            
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    
    },
    {
        enabled:!!cgId,
    refetchOnWindowFocus: false,  

    }
    
    );
};
