import { useTranslation } from "react-i18next";

interface CADLandingProps {
  handleOptionsChange: any,
  selectedOptions:string[]

}

export const CADLanding = ({handleOptionsChange,selectedOptions}:CADLandingProps) => {
  const { t} = useTranslation();
  const radio_button_array = [
    { option1: t('CAD.CADlanding.OptionYes') , option2: t('CAD.CADlanding.OptionNo') , description: t('CAD.CADlanding.descrip1') , for: t('CAD.CADlanding.for1') },
    { option1: t('CAD.CADlanding.OptionYes') , option2: t('CAD.CADlanding.OptionNo') , description: t('CAD.CADlanding.descrip2'), for: t('CAD.CADlanding.for2') },
    { option1: t('CAD.CADlanding.OptionYes') , option2: t('CAD.CADlanding.OptionNo') , description: t('CAD.CADlanding.descrip3'), for: "" },
    { option1: t('CAD.CADlanding.OptionYes') , option2: t('CAD.CADlanding.OptionNo') , description: t('CAD.CADlanding.descrip4'), for: "" },
    { option1: t('CAD.CADlanding.OptionYes') , option2: t('CAD.CADlanding.OptionNo') , description: t('CAD.CADlanding.descrip5'), for: t('CAD.CADlanding.for5') },
    { option1: t('CAD.CADlanding.OptionYes') , option2: t('CAD.CADlanding.OptionNo') , description: t('CAD.CADlanding.descrip6'), for: t('CAD.CADlanding.for6')  }
  ];
  const handleOptionChange = (index: number, selectedOption: string) => {
    handleOptionsChange(index,selectedOption);
  };

  const getTitleFromIndex = (ind: number) => {
    return radio_button_array[ind].for===""?null:radio_button_array[ind].for
  };

  return (

    


    <div>
      {radio_button_array.map((record, index) => (

        <>
        <div key={index}>

     
        {getTitleFromIndex(index) && (
          <div   className={'cco_text cco_text_11'}>
            <div className={'cco_text_inner'}>
              <h3>{getTitleFromIndex(index)}</h3>
            </div>
          </div>
        )}

          <div  className="radio-button-item">

            <p className="cco_text_6">{record.description}</p>
            <div className="radio-buttons">
              <label className={'radio-buttons-yes'}>
                <input
                  type="radio"
                  name={`option${index}`}
                  value="Yes"
                  checked={selectedOptions[index] === "Yes"}
                  onChange={() => handleOptionChange(index, "Yes")}
                  style={{ marginRight: "5px" }}
                  className="radio-button" />
                {t('CAD.CADlanding.OptionYes') }
              </label>
              <label className={'radio-buttons-no'}>
                <input
                  type="radio"
                  name={`option${index}`}
                  value="No"
                  checked={selectedOptions[index] === "No"}
                  onChange={() => handleOptionChange(index, "No")}
                  style={{ marginRight: "5px" }}
                  className="radio-button" />
                {t('CAD.CADlanding.OptionNo') }
              </label>
            </div>
          </div>
          </div>
          </>
      ))}
    </div>
  );
};