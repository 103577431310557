import { NullBadgeUrl, showContactCredentialCardBorder } from '../../tools/utils';
import { Credentials } from '../../types/allcredential';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


interface IContactCredentialCardProps {
    index: number | null;
    credential: Credentials;
    ProviderAccessControl?:string | null;
}

export const ContactCredentialCard = ({credential,index,ProviderAccessControl}: IContactCredentialCardProps) => {
    const { t} = useTranslation();

    const [showcardBorder, setShowcardBorder] = useState(false);

    useEffect(() => {
        if (credential.ExpiryDate != '0001-01-01T00:00:00') {
            showContactCredentialCardBorder(credential.ExpiryDate, setShowcardBorder);
        }
    }, [credential.ExpiryDate]);
    
    return (
        <div className="cco_group_item tpCards" key={credential.Id+index} >
        <a
            href={
                ProviderAccessControl !== 'Suspended'
                    ? '/my-credential/' +
                      credential.ContactCredentialId
                    : ''
            }
            onClick={(e) => {
                if (ProviderAccessControl === 'Suspended') {
                  e.preventDefault();
                }
              }}
              
        >
            <div className="cco_card cco_card_myCredential" style={{ border:showcardBorder?'4px red solid':''}}>
                <div className="cco_card_body">
                    <div className="cco_card_aside">
                        <div className="cco_img">
                            <span className="cco_img_wrap">
                                <img
                                    style={{
                                        border:
                                            (credential.StatusReason ===
                                                'Approved'|| credential.StatusReason === 'PendingRenewal') &&
                                            credential.BadgeUrl !=
                                                null
                                                ? ''
                                                : '1px solid rgb(0 0 0 / 5%)',
                                        borderRadius:
                                        (credential.StatusReason ===
                                            'Approved'|| credential.StatusReason === 'PendingRenewal')&&
                                            credential.BadgeUrl !=
                                                null
                                                ? ''
                                                : '23px',
                                    }}
                                    alt={credential?.myCCOLabel}
                                    src={
                                        (credential.StatusReason ===
                                            'Approved'|| credential.StatusReason === 'PendingRenewal')&&
                                        credential.BadgeUrl !=
                                            null
                                            ? credential.BadgeUrl
                                            : NullBadgeUrl
                                    }
                                />
                            </span>
                        </div>
                    </div>
                    <div
                        className="cco_card_main"
                        style={{
                            width: '100%',
                            padding: '10px',
                        }}
                    >
                        <div className="cco_text cco_text_myCredential">
                            <div className="cco_text_inner">
                                <h3>
                                    {credential?.myCCOLabel}
                                </h3>
                                <div>
                                {t("cards.ContactCredentialCard.Status")}:{' '}
                                    {credential.StatusReason ? credential.StatusReason.replace(/([a-z])([A-Z])/g, '$1 $2'):''}
                                </div>
                                <div>
                                {t("cards.ContactCredentialCard.TrainingDue")}:{' '}
                                    {credential?.ExpiryDate?.split(
                                        'T'
                                    )[0] === '0001-01-01'
                                        ? 'Not Available'
                                        : credential?.ExpiryDate?.split(
                                              'T'
                                          )[0]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </a>

        {/* <div className="cco_row cco_row_1" style={{paddingTop:'5px'}}>
                        <div className="cco_col ">
                            <ul className="cco_menu cco_menu_0">
                                <li
                                    className="cco_menu_item cco_menu_item_primary"
                                    style={{
                                        marginBottom: '0px',
                                    }}
                                >
                                  {showcardBorder &&  <a 
                                        style={{ height: 'auto'}} 
                                        href={`${'/credential-renew'}`}
                                        target={'_blank'}
                                    >
                                        Renew Now
                                    </a>
}
                                  
                                  {credential.StatusReason==='Pending' && 
                                  <a 
                                  style={{ height: 'auto'}} 
                                  href={`${'/courses-dashboard'}`}
                                  target={'_blank'}
                              >
                                  Complete Training
                              </a>
                                  }  
                                </li>
                            </ul>
                        </div>
        </div> */}
    </div>
    );
};
