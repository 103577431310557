type IGenericObject = {
    [key: string]: any;
};

interface IRequestOptions {
    endpoint: string;
    signal?: AbortSignal;
    data?: IGenericObject;
}

export const faceRequest = async ({ endpoint, data}: IRequestOptions) => {
    const headers: IGenericObject = {};

    const response = await fetch(`${'https://dev-cco-eil-face.cognitiveservices.azure.com/'}${endpoint}?${data && `${new URLSearchParams(data)}`}`,
        {
            method:'POST',
            body: JSON.stringify(data),
            headers: {
                ...headers,
                "Accept": 'application/json',
               'Ocp-Apim-Subscription-Key':'5fa4e1a8a86544978676d2a21b52a7ee',
               'Content-Type':'application/json'
            },
        }
    );

    const success = response.ok;

    return {
        success,
        data: await response.json(),
        status:response.status
    };
};
