import { useMsal } from '@azure/msal-react';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import React from 'react';

interface PropType {
    component: React.FC;
}

const PrivateRoute: FC<PropType> = ({ component: Component }) => {
    const {accounts}=useMsal();
    if (accounts.length>0) return <Component />;
    return <Navigate to={'/'} />
};

export default PrivateRoute;