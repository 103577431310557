import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject2 } from '../types/allcredential';

export const useCredential = (credentialId?: string | null, language?: string |null) => {
    return useQuery<RootObject2, Error>(
        ['credential', credentialId, language], // Add language to the query key
        async () => {
            const jsonResponse = await request({
                endpoint: 'credential',
                data: {
                    credentialId: credentialId,
                    language: language, // Pass the language parameter
                },
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled: !!credentialId && !!language, // Enable the query only if both credentialId and language are available
            refetchOnWindowFocus: false,
        }
    );
};
