import type { IOtherTSC } from './valuesTypes';

import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useActiveTSCs } from '../../hooks/useActiveTSCs';

interface OtherTSCProps {
    selectedTSCs: IOtherTSC[];
    setSelectedTSCs: (selectedTSCs: IOtherTSC[]) => void;
}

export const OtherTSC = ({ selectedTSCs, setSelectedTSCs }: OtherTSCProps) => {
    const [q, setQ] = useState<string>('');

    const query = useActiveTSCs(q ,"TSC-Practical");
    const data = query.data?.data.Data || [];

    return (
        <>
            <div className="cco_form_row">
                <div className="cco_form_col">
                    <h2 className="cco_form_heading">7. Other Test Site Coordinators</h2>
                    <div className="cco_form_wrap">
                        <div className="cco_form_row">
                        <div className="cco_form_col cco_form_col_1">
                                
                                <div className="cco_form_label">Active TSCs</div>
                                <span className='text-muted small-text text-primary' style={{fontSize:'13px'}}>&nbsp; &nbsp; Enter First Name or Last Name (not full name) to search </span>

                                <div className="cco_form_card">
                                    <div className="cco_form_card_header">
                                        <DebounceInput
                                            type="text"
                                            minLength={2}
                                            debounceTimeout={250}
                                            placeholder="Search Active TSCs"
                                            className="cco_form_field cco_form_field_1"
                                            onChange={event => setQ(event.target.value)}
                                        />
                                    </div>

                                    <div className="cco_form_card_body">
                                        {query.isLoading && 'idle' !== query.fetchStatus ? (
                                            <p>Searching...</p>
                                        ) : query.error ? (
                                            <p>{query.error.message}</p>
                                        ) : data.length > 0 ? (
                                            data.map(tsc => {
                                                return (
                                                    <div
                                                        key={tsc.ContactId}
                                                        className="cco_form_fieldset cco_form_fieldset_0"
                                                    >
                                                        <div className="cco_form_fieldset_item cco_form_fieldset_item_large">
                                                            {tsc.FirstName} {tsc.LastName}
                                                        </div>

                                                        {!selectedTSCs.some(e => e.id === tsc.ContactId) && (
                                                            <div className="cco_form_fieldset_item">
                                                                <button
                                                                    type="button"
                                                                    className="cco_form_btn cco_form_btn_0"
                                                                    onClick={() => {
                                                                        setSelectedTSCs([
                                                                            ...selectedTSCs,
                                                                            {
                                                                                primary: false,
                                                                                id: tsc.ContactId,
                                                                                name: `${tsc.FirstName} ${tsc.LastName}`,
                                                                            },
                                                                        ]);
                                                                    }}
                                                                >
                                                                    Select
                                                                </button>
                                                                <br/>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            !query.isLoading && <p>No active TSCs found</p>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div className="cco_form_col cco_form_col_2">
                                <div className="cco_form_label">Selected TSCs</div>

                                <div className="cco_form_card">
                                    <div className="cco_form_card_body">
                                        {selectedTSCs.length > 0 ? (
                                            selectedTSCs.map(tsc => {
                                                return (
                                                    <div key={tsc.id} className="cco_form_fieldset cco_form_fieldset_0">
                                                        <div className="cco_form_fieldset_item cco_form_fieldset_item_large">
                                                            {tsc.name}
                                                        </div>
                                                        <div className="cco_form_fieldset_item">
                                                            <div className="cco_form_fieldset cco_form_fieldset_1">
                                                                {tsc.primary && (
                                                                    <div className="cco_form_fieldset_item">
                                                                        <div className="cco_form_blurb cco_form_blurb_0">
                                                                            Primary
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {!tsc.primary && (
                                                                    <div className="cco_form_fieldset_item" style={{marginBottom:"20px",marginTop:"20px" }}>
                                                                        <button
                                                                            type="button"
                                                                            className="cco_form_btn cco_form_btn_0"
                                                                            onClick={() => {
                                                                                setSelectedTSCs(
                                                                                    selectedTSCs.map(_tsc => {
                                                                                        if (_tsc.id === tsc.id) {
                                                                                            return {
                                                                                                ..._tsc,
                                                                                                primary: true,
                                                                                            };
                                                                                        }

                                                                                        return {
                                                                                            ..._tsc,
                                                                                            primary: false,
                                                                                        };
                                                                                    })
                                                                                );
                                                                            }}
                                                                        >
                                                                            Set Primary
                                                                        </button>
                                                                    </div>
                                                                )}

                                                                <div className="cco_form_fieldset_item">
                                                                    <button
                                                                        type="button"
                                                                        className="cco_form_btn cco_form_btn_1"
                                                                        onClick={() => {
                                                                            setSelectedTSCs(
                                                                                selectedTSCs.filter(_tsc => {
                                                                                    return _tsc.id !== tsc.id;
                                                                                })
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p>No other TSCs selected</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
