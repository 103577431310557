import type { Root } from '../../types/dashboard';
import { createNewSortInstance } from 'fast-sort';
import { Loader } from '../../components/Loader';
import { TSC as TSCComponent } from '../../components/TSC';
import { useEOTTestSites } from '../../hooks/useEOTTestSites';
import { useUser, useUserProfilePicture } from '../../hooks/useUser';
import { useCredentialValidate } from '../../hooks/useCredentialValidate';
import { useEffect } from 'react';
import { customURLs, getToken } from '../../tools/utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useState } from 'react';

const naturalSort = createNewSortInstance({
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
});

export const TSCEOT = () => {
    const { t} = useTranslation();
    const query = useUser();
    const userProfilePicture = useUserProfilePicture();

    const { data: eotData, isLoading: eotISLoading, error: eotError } = useEOTTestSites();
    const token = getToken()

    const eotSites = naturalSort(eotData?.data.Data || []).asc(site => site.TestSiteNumber);

    //const userProfileData = userProfilePicture.data;
    const data = (query.data?.data || {}) as unknown as Root['data'];

    const payment = eotSites.filter(test => 'PendingPayment' === test.Status);
    const pending = eotSites.filter(test => 'PendingReview' === test.Status);
    const approved = eotSites.filter(test => 'Approved' === test.Status);
    const expired = eotSites.filter(test => 'Expired' === test.Status);
    const validateQuery = useCredentialValidate('TSC-EOT');
    const validateData = validateQuery.data?.data;
    useEffect(() => {
        if(validateData){

     if(!validateData.Data)
        {
            window.location.href = '/testing-personnel/';
        }
    }
      // Add any other code that needs to be executed when the page loads
  }, [validateData]);

    return (
        <div id="main-content">
        <div className="cco_content_area cco_content_area_0">
            <article className="react-container">
    
        <div className="cco_section cco_section_0">
            <div className="cco_row">
                <div className="cco_col">
                    <div className="cco_text cco_text_11">
                        <div className="cco_text_inner">
                            <strong>
                                <a href="/testing-personnel"> {t("EOTComponents.EOTComponent.TestingPersonnel")} </a>
                            </strong>{' '}
                            {'>'} {t("EOTComponents.EOTComponent.TestSiteLocations")}
                        </div>
                    </div>
                </div>
            </div>
      


            <div className="cco_row cco_row_0">
                {query.isLoading || userProfilePicture.isLoading ? (
                    <Loader />
                ) : query.error ? (
                    <p>{query.error.message}</p>
                ) : (
                    <>
                        {/* <div className="cco_col cco_col_4">
                            <div className="cco_img cco_img_1">
                                <a className="cco_img_wrap" href="/account">
                                    <img src="https://trueb2c.live/wp-content/themes/Divi-Child/assets/images/Missing_Avatar_2.svg" alt="" />

                                    <div className="cco_img_icon">
                                        <span className="cco_img_icon_path_0"></span>
                                        <span className="cco_img_icon_path_1"></span>
                                        <span className="cco_img_icon_path_2"></span>
                                    </div>

                                    <div className="cco_img_caption">Upload Photo</div>

                                    {userProfileData?.success ? (
                                        <>
                                            <img src={userProfileData.data} alt="user profile picture" />

                                            <div className="cco_img_caption cco_img_caption_hover">Upload Photo</div>
                                        </>
                                    ) : (
                                        <>
                                            <img src="https://trueb2c.live/wp-content/themes/Divi-Child/assets/images/Missing_Avatar_2.svg" alt="" />

                                            <div className="cco_img_icon">
                                                <span className="cco_img_icon_path_0"></span>
                                                <span className="cco_img_icon_path_1"></span>
                                                <span className="cco_img_icon_path_2"></span>
                                            </div>

                                            <div className="cco_img_caption">Upload Photo</div>
                                        </>
                                    )}
                                </a>
                            </div>
                        </div> */}

                        {/* <div className="cco_col cco_col_5"> */}
                        <div className="cco_col cco_col_12">
                            <div className="cco_text cco_text_1">
                                <div className="cco_text_inner">
                                    <h1>
                                        {data.Name.FullName}
                                        <span className="divider" />
                                        <small>
                                            {t("EOTComponents.EOTComponent.CCOID")} <strong>{data.SecondaryKeys[0].Key}</strong>
                                        </small>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <>
                <div className="cco_row cco_row_7">
                    <div className="cco_col cco_col_6">
                        <div className="cco_text cco_text_10">
                            <div className="cco_text_inner">
                                <h2>{t("EOTComponents.EOTComponent.MyEOTTestSites")}</h2>
                            </div>
                        </div>
                    </div>

                    <div className="cco_col cco_col_7">
                        <div className="cco_btn cco_btn_3">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a
                                        href={`${token && customURLs?.manageMyTestAdministrationsUrl?.button_url
                                            ? customURLs.manageMyTestAdministrationsUrl.button_url + '?id_token=' + token
                                            : '#'}`}
                                        target="_blank"
                                        rel="noreferrer">
                                        {customURLs?.manageMyTestAdministrationsUrl?.button_text || 'Button Text'}
                                    </a>



                        </div>
                    </div>
                </div>

                <div className="cco_row cco_row_3">
                    <div className="cco_col">
                        {/*<div className="cco_text cco_text_6">*/}
                        {/*    <div className="cco_text_inner">*/}
                        {/*        <strong>Active</strong>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/* {eotISLoading ? (
                            <Loader />
                        ) : eotError ? (
                            <p>{eotError.message}</p>
                        ) : eotSites.length < 1 ? (
                            <p>No test sites found</p>
                        ) : (
                            <ul className="cco_accordion">
                                {eotSites.map(site => (
                                    <TSCComponent key={site.LocationId} item={site} />
                                ))}
                            </ul>
                        )} */}

                        {/*<div className="cco_text cco_text_6">*/}
                        {/*    <div className="cco_text_inner">*/}
                        {/*        <strong>Expired</strong>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<ul className="cco_accordion">*/}
                        {/*    <li className="cco_accordion_item active">*/}
                        {/*        <div className="cco_accordion_item_header">*/}
                        {/*            <div className="cco_text cco_text_3">*/}
                        {/*                <div className="cco_text_inner">*/}
                        {/*                    <h3>CaneConstruct - 4612 Jehovah Drive</h3>*/}
                        {/*                    <div>EOT-2345</div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <button className="cco_accordion_item_toggle">*/}
                        {/*                <span className="cco_accordion_item_toggle_text">View</span>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*        <div className="cco_accordion_item_body">*/}
                        {/*            <div className="cco_accordion_item_body_inner">*/}
                        {/*                <table className="cco_table cco_table_1">*/}
                        {/*                    <tbody className="cco_table_body">*/}
                        {/*                        <tr className="cco_table_row">*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_medium">*/}
                        {/*                                <div className="cco_blurb cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Company Name</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>CaneConstruct</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Address</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>4612 Jehovah Drive Fredericksburg, VA 22408</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_small">*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_center cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Primary TSC</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>John Coordinator</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_center cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Other Assigned TSCs</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>Tom Jones, Jane Johnson</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_small">*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Status</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>Expired</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Expire Date</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>1/1/2019</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}
                        {/*                    </tbody>*/}
                        {/*                </table>*/}

                        {/*                <ul className="cco_menu cco_menu_3">*/}
                        {/*                    <li className="cco_menu_item">*/}
                        {/*                        <a href="#">Edit</a>*/}
                        {/*                    </li>*/}
                        {/*                    <li className="cco_menu_item">*/}
                        {/*                        <a href="#">Renew Now</a>*/}
                        {/*                    </li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </li>*/}
                        {/*    <li className="cco_accordion_item">*/}
                        {/*        <div className="cco_accordion_item_header">*/}
                        {/*            <div className="cco_text cco_text_3">*/}
                        {/*                <div className="cco_text_inner">*/}
                        {/*                    <h3>CaneConstruct - 4612 Jehovah Drive</h3>*/}
                        {/*                    <div>EOT-2345</div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <button className="cco_accordion_item_toggle">*/}
                        {/*                <span className="cco_accordion_item_toggle_text">View</span>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*        <div className="cco_accordion_item_body">*/}
                        {/*            <div className="cco_accordion_item_body_inner">*/}
                        {/*                <table className="cco_table cco_table_1">*/}
                        {/*                    <tbody className="cco_table_body">*/}
                        {/*                        <tr className="cco_table_row">*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_medium">*/}
                        {/*                                <div className="cco_blurb cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Company Name</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>CaneConstruct</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Address</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>4612 Jehovah Drive Fredericksburg, VA 22408</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_small">*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_center cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Primary TSC</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>John Coordinator</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_center cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Other Assigned TSCs</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>Tom Jones, Jane Johnson</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_small">*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Status</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>Expired</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Expire Date</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>1/1/2019</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}
                        {/*                    </tbody>*/}
                        {/*                </table>*/}

                        {/*                <ul className="cco_menu cco_menu_3">*/}
                        {/*                    <li className="cco_menu_item">*/}
                        {/*                        <a href="#">Edit</a>*/}
                        {/*                    </li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}

                        {/* <div className="cco_text cco_text_6">
                        <div className="cco_text_inner">
                            <strong>Draft</strong>
                        </div>
                    </div> */}
                        {/*<ul className="cco_accordion">*/}
                        {/*    <li className="cco_accordion_item">*/}
                        {/*        <div className="cco_accordion_item_header">*/}
                        {/*            <div className="cco_text cco_text_3">*/}
                        {/*                <div className="cco_text_inner">*/}
                        {/*                    <h3>CaneConstruct - 4612 Jehovah Drive</h3>*/}
                        {/*                    <div>EOT-2345</div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <button className="cco_accordion_item_toggle">*/}
                        {/*                <span className="cco_accordion_item_toggle_text">View</span>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*        <div className="cco_accordion_item_body">*/}
                        {/*            <div className="cco_accordion_item_body_inner">*/}
                        {/*                <table className="cco_table cco_table_1">*/}
                        {/*                    <tbody className="cco_table_body">*/}
                        {/*                        <tr className="cco_table_row">*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_medium">*/}
                        {/*                                <div className="cco_blurb cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Company Name</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>CaneConstruct</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Address</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>4612 Jehovah Drive Fredericksburg, VA 22408</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_small">*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_center cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Primary TSC</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>John Coordinator</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_center cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Other Assigned TSCs</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>Tom Jones, Jane Johnson</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                            <td className="cco_table_cell cco_table_cell_small">*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Status</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>Expired</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">*/}
                        {/*                                    <div className="cco_blurb_label">Expire Date</div>*/}
                        {/*                                    <div className="cco_blurb_value">*/}
                        {/*                                        <strong>1/1/2019</strong>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}
                        {/*                    </tbody>*/}
                        {/*                </table>*/}

                        {/*                <ul className="cco_menu cco_menu_3">*/}
                        {/*                    <li className="cco_menu_item">*/}
                        {/*                        <a href="#">Edit</a>*/}
                        {/*                    </li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}

                        <ul className="cco_menu cco_menu_4">
                            <li className="cco_menu_item cco_menu_item_icon cco_menu_item_icon_plus">
                                {/* <a href="/tsc-eot/location-new">Add new EOT test site</a> */}
                                <Link to="/tsc-eot/location-new" state={{ eotId: null, single: false }}>{t("EOTComponents.EOTComponent.AddnewEOTtestsite")}</Link>
                                {/* <a style={{ cursor: "pointer" }} onClick={handleButtonClick}>Add new EOT test site</a> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </>

            {eotISLoading ? (
              <div className="cco_row cco_row_3">
                <Loader />
              </div>
            ) : eotError ? (
              <p>{eotError.message}</p>
            ) : eotSites.length < 1 ? (
              <div className="cco_row cco_row_3">
                <p>{t("EOTComponents.EOTComponent.Notestsitesfound")}</p>
              </div>
            ) : (
              <>
                {payment.length > 0 && (
                  <>
                    <div className="cco_row cco_row_7">
                      <div className="cco_col cco_col_6">
                        <div className="cco_text cco_text_10">
                          <div className="cco_text_inner">
                            <h2>{t("EOTComponents.EOTComponent.MyPendingPaymentEOTTestSites")}</h2>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="cco_row cco_row_3">
                      <div className="cco_col">
                        <ul className="cco_accordion">
                          {payment.map((site) => (
                            <TSCComponent key={site.LocationId} item={site} type="PendingPayment" />
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}

            {/* My pending review EOT test sites */}

            {pending.length > 0 && (
                <>
                    <div className="cco_row cco_row_7">
                        <div className="cco_col cco_col_6">
                            <div className="cco_text cco_text_10">
                                <div className="cco_text_inner">
                                    <h2>{t("EOTComponents.EOTComponent.MyPendingReviewEOTTestSites")}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cco_row cco_row_3">
                        <div className="cco_col">
                            {eotISLoading ? (
                                <Loader />
                            ) : eotError ? (
                                <p>{eotError.message}</p>
                            ) : pending.length < 1 ? (
                                <p>{t("EOTComponents.EOTComponent.Notestsitesfound")}</p>
                            ) : (
                                <ul className="cco_accordion">
                                    {pending.map(site => (
                                        <TSCComponent key={site.LocationId} item={site} type="PendingReview" />
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* My approved EOT test sites */}

            {approved.length > 0 && (
                <>
                    <div className="cco_row cco_row_7">
                        <div className="cco_col cco_col_6">
                            <div className="cco_text cco_text_10">
                                <div className="cco_text_inner">
                                    <h2>{t("EOTComponents.EOTComponent.MyApprovedEOTTestSites")}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cco_row cco_row_3">
                        <div className="cco_col">
                            {eotISLoading ? (
                                <Loader />
                            ) : eotError ? (
                                <p>{eotError.message}</p>
                            ) : approved.length < 1 ? (
                                <p>{t("EOTComponents.EOTComponent.Notestsitesfound")}</p>
                            ) : (
                                <ul className="cco_accordion">
                                    {approved.map(site => (
                                        <TSCComponent key={site.LocationId} item={site} type="Approved" />
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* My expired EOT test sites */}

            {expired.length > 0 && (
                <>
                    <div className="cco_row cco_row_7">
                        <div className="cco_col cco_col_6">
                            <div className="cco_text cco_text_10">
                                <div className="cco_text_inner">
                                    <h2>{t("EOTComponents.EOTComponent.MyExpiredEOTTestSites")}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cco_row cco_row_3">
                        <div className="cco_col">
                            {eotISLoading ? (
                                <Loader />
                            ) : eotError ? (
                                <p>{eotError.message}</p>
                            ) : expired.length < 1 ? (
                                <p>{t("EOTComponents.EOTComponent.Notestsitesfound")}</p>
                            ) : (
                                <ul className="cco_accordion">
                                    {expired.map(site => (
                                        <TSCComponent key={site.LocationId} item={site} type="Expired" />
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    </>
                )}
              </>
            )}
         



    </div>
        </article>
        </div>
        </div>
    );
};


