import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject } from '../types/authdata';

export const useAuthData = (name:string) => {
    return useQuery<RootObject, Error>(['authdata'], async () => {
        const jsonResponse = await request({
            endpoint: 'settings/anet/'+name
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    },{
    refetchOnWindowFocus: false,  
    });
};
