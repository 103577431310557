import classNames from 'classnames';

import type { IValuesPrac, IOtherTSC } from './valuesTypes';
import type { FormikErrors, FormikHelpers } from 'formik';

import { Formik } from 'formik';
import { Summary } from './Summary';
import { OtherTSC } from './OtherTSC';
import { useEot } from '../../hooks/useEot';
import { useEffect, useState } from 'react';
import { Attestation } from './Attestation';
import { Loader, BlockUI } from '../../components/Loader';
import { ExamOffered } from './ExamOffered';
import { useUpload } from '../../hooks/useUpload';
import { screenedValuesPrac } from '../../tools/constants';
import { LocationDoorSign } from './LocationDoorSign';
import { newPractical } from '../../schemas/newPractical';
import { LocationInformation } from './LocationInformation';
import { BillingInformation } from './BillingInformation';
import { singlePractical } from '../../schemas/singlePractical';
import { useMultiStepForm } from '../../hooks/useMultiStepForm';
import { useMutationQuery } from '../../hooks/usePracMutationQuery';
import { TestSiteRepresentativeInformation } from './TestSiteRepresentativeInformation';
import { useLocationProduct } from '../../hooks/useLocationProduct';
import { useAuthData } from '../../hooks/useAuthData';
import { LocationProductData } from '../../types/product';
import { useCredentialValidate } from '../../hooks/useCredentialValidate';
import { useLocation } from 'react-router-dom';
import AuthorizeSessionTimer from '../../components/PaymentTimer/AuthorizeSessionTimer';
import { useTranslation } from 'react-i18next';

const initialValues: IValuesPrac = {
    prevState: {
        fullName: '',
        canBeEdited: true,
        termsAndConditions: null,
    },
    zip: '',
    city: '',
    email: '',
    state: 'default',
    street1: '',
    street2: '',
    timezone: "default",
    doorSign: null,
    sitePlan: null,
    fullName: '',
    submitted: false,
    phoneNumber: '',
    companyName: '',
    attestation: [],
    PracSiteTypes: [],
    country: 'default',
    emailConfirmation: '',
    additionalSecurity: '',
    termsAndConditions: null,
    isASecureFacility: 'default',
    Address: "",
    Amount: 0,
    AuthNetTransactionID: "",
    BillToEmail: "",
    BillToName: "",
    City: "",
    CreditCardType: "VISA",
    FirstName: "",
    LastFour: "",
    LastName: "",
    Paymenttype: "Credit Card",
    State: "",
    Zip: "",
    dataDescriptor: '',
    dataValue: '',
    CardNumber: '',
    BillingCountry: "",
    BillingPhone: "",
    PracticalAttestation: []
};

const attestations: string[] = ['initial values'];
var examoffered: string[] = ['initial values'];
const PracticalAttestation: string[] = ['initial values'];
export const NewPracLocation = () => {
    const { t } = useTranslation();
    const {eotId=null , single= false}=useLocation().state;
    const validateQuery = useCredentialValidate('TSC-Practical');
    const validateData = validateQuery.data?.data;
    const [creditData, setCreditData] = useState({ LastFour: '', dataDescriptor: '', dataValue: '', CardNumber: '' });
    const [creditInfo, setCreditInfo] = useState(false);
    const [ccError, setCCError] = useState(false);
    const [showLoading, setLoading] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const handleSubmits = (response: any) => {

        if (response.messages.message[0].code == 'I_WC_01') {
            setCreditInfo(true);

            let obj = {
                LastFour: response.encryptedCardData.bin.substr(response.encryptedCardData.bin.length - 4),
                dataDescriptor: response.opaqueData.dataDescriptor,
                dataValue: response.opaqueData.dataValue,
                CardNumber: response.encryptedCardData.cardNumber
            }
            setCreditData(obj);
            setCCError(false);
        }
    }

    const handleTermsAndConditionsChange = (event: any) => {
        setTermsAndConditions(event.target.checked);
    };

    const uploadFile = useUpload();
    const eotQuery = useEot(eotId);
    const authQuery = useAuthData('location');
    const AuthData = authQuery.data?.data.Data;
    const productQuery = useLocationProduct('Practical Test Site Annual Fee');
    const eotData = eotQuery.data?.data.Data;
    examoffered = eotData?.PracSiteTypes.map((x: { Id: any; }) => x.Id);
    const productData = productQuery.data?.data.Data || {} as LocationProductData;
    const [error, setError] = useState<string | null>(null);

    const authData = {
        apiLoginID: AuthData?.ApiLoginID || '',
        clientKey: AuthData?.ClientKey || '',
        IsSandbox: AuthData?.IsSandbox
    };
    const [selectedTSCs, setSelectedTSCs] = useState<IOtherTSC[]>([]);

    useEffect(() => {
        if (eotData) {
            if (eotData.Restroom) {
                attestations.push('restroom');
            }

            if (eotData.AdequateParking) {
                attestations.push('parking');
            }
            if (eotData.IsPretest) {
                attestations.push('pretest');
            }

            if (eotData.IsPracticalAttestation) {
                PracticalAttestation.push('PracticalAttestation')
            }

            if (eotData.AdditionalTSCs) {
                setSelectedTSCs(
                    eotData.AdditionalTSCs.map(tsc => ({ id: tsc.TscId, name: tsc.TscName, primary: !!tsc.IsPrimaryTsc }))
                );
            }
        }
    }, [eotData]);

    useEffect(() => {
        if (validateData) {

            if (!validateData.Data) {
                window.location.href = '/testing-personnel/';
            }
            console.log(eotData)
            if (single && eotQuery.error) {
                window.location.href = '/testing-personnel/';
            }
        }

    }, [validateData, eotQuery]);

    const { back, next, skipUsed, isLastStep, isFirstStep, goToLastStep, step: Component, currentStepIndex } = useMultiStepForm(
        {
            single,
        },
        [
            <LocationInformation key={1} singlePage={single} />,
            <LocationDoorSign key={2} />,
            <ExamOffered key={3} />,
            <TestSiteRepresentativeInformation key={4} />,
            <Attestation key={5} />,
            ...('Approved' === eotData?.Status
                ? [<OtherTSC key={6} selectedTSCs={selectedTSCs} setSelectedTSCs={setSelectedTSCs} />]
                : []),
            <BillingInformation key={7} singlePage={single} handler={handleSubmits} CardNumber={creditData.CardNumber} authData={authData} isCreditInfo={creditInfo} showCreditCardErrorMessage={ccError} ProductData={productData} />
            ,
            <Summary key={8} singlePage={single} approved={'Approved' === eotData?.Status} selectedTSCs={selectedTSCs} CreditCard={creditData.CardNumber} PaymentData={productData} />,

        ]
    );

    const practicalMutation = useMutationQuery();

    useEffect(() => {
        if (practicalMutation.data && !practicalMutation.error) {
            if (single) {
                window.location.href = '/tsc-prac';
            } else {
                window.location.href = single ? `/tsc-prac/prac-edit/${practicalMutation.data?.data?.Data || 'location-id'}`
                    : '/tsc-prac';
            }
        }
    }, [practicalMutation.data, practicalMutation.error, single]);

    const submitHandler = (values: IValuesPrac, { setSubmitting }: FormikHelpers<IValuesPrac>) => {
        if (single === false) {
            setLoading(true);
        }
        setError(null);
        console.log(single)
        const upload = [];
        const uploadedFiles: any[] = [];

        const data: any = single
            ? {
                OtherTSC: [],
                PrimaryTSC: null,
                LocationId: eotData?.LocationId,
                IsSecuredSite: 'yes' === values.isASecureFacility,
                PETSRep: {
                    Phone: values.phoneNumber,
                    Email: values.email,
                    FullName: values.fullName,
                    TermsAndConditionsFile: values.termsAndConditions,
                },
                PracSiteTypes:examoffered
            }
            : {
                address1: values.street1,
                address2: values.street2,
                timezoneId: values.timezone,
                city: values.city,
                zip: values.zip,
                companyName: values.companyName,
                stateProvinceId: values.state,
                countryId: values.country,
                IsSecuredSite: 'yes' === values.isASecureFacility,
                ProductId: productData?.ProductId,
                PhotoOfSignOnDoorFile: values.doorSign,
                Payment: {
                    Paymenttype: 'Credit Card',
                    AuthNetTransactionID: '',
                    LastFour: creditData.LastFour,
                    Amount: productData?.ProductPrice,
                    PaymentData: {
                        BillToName: values.FirstName + " " + values.LastName,
                        BillToEmail: values.BillToEmail,
                        CreditCardType: '',
                        FirstName: values.FirstName,
                        LastName: values.LastName,
                        Address: values.Address,
                        City: values.City,
                        State: values.State,
                        Zip: values.Zip,
                        dataDescriptor: creditData.dataDescriptor,
                        dataValue: creditData.dataValue,
                        BillingPhone: values.BillingPhone,
                        BillingCountry: values.BillingCountry
                    }
                },
                SitePlanFile: values.sitePlan,
                representative: {
                    fullName: values.fullName,
                    email: values.email,
                    phone: values.phoneNumber,
                    termsAndConditionsFile: values.termsAndConditions,
                },
                attestation: {
                    restroom: values.attestation.includes('restroom'),
                    adequateParking: values.attestation.includes('parking'),
                    pretest: values.attestation.includes('pretest'),
                },
                PracSiteTypes:values.PracSiteTypes,
                IsPracticalAttestation : values.PracticalAttestation.includes('PracticalAttestation'),
            };

        if (data.SitePlanFile instanceof File) {
            upload.push({
                name: 'SitePlan',
                file: data.SitePlanFile,
            });
        }

        if (data.PhotoOfSignOnDoorFile instanceof File) {
            upload.push({
                name: 'PhotoOfSignOnDoor',
                file: data.PhotoOfSignOnDoorFile,
            });
        }

        if (data.representative?.termsAndConditionsFile instanceof File) {
            upload.push({
                name: 'termsAndConditionsFile',
                file: data.representative.termsAndConditionsFile,
            });
        }

        if (data.PETSRep?.TermsAndConditionsFile instanceof File) {

            upload.push({
                name: 'termsAndConditionsFile',
                file: data.PETSRep.TermsAndConditionsFile,
            });
        }

        if (upload.length) {
            upload.forEach(file => {
                const formData = new FormData();
                formData.append('file', file.file);

                uploadedFiles.push(
                    uploadFile.mutateAsync({
                        body: formData,
                        type: file.file.type.includes('image') ? 'image' : 'file',
                    })
                );
            });
        }

        Promise.all(uploadedFiles)
            .then(files => {
                if (3 === files.length) {
                    if (data.SitePlanFile) {
                        data.SitePlanFile = files[0].data.Data.FileName;
                    }

                    if (data.PhotoOfSignOnDoorFile) {
                        data.PhotoOfSignOnDoorFile = files[1].data.Data.FileName;
                    }

                    if (data.representative?.termsAndConditionsFile) {
                        data.representative.termsAndConditionsFile = files[2].data.Data.FileName;
                    }
                    // if (data.PhotoofPracticalTestArea) {
                    //     data.PhotoofPracticalTestArea = files[3].data.Data.FileName;
                    // }
                } 
                else if (files.length > 0) {
                    if (data.PETSRep?.TermsAndConditionsFile) {
                        data.PETSRep.TermsAndConditionsFile = files[0].data.Data.FileName;
                    }
                }

                if (selectedTSCs.length) {
                    data.AdditionalTSCs = selectedTSCs.map(tsc => ({
                        TscId: tsc.id,
                        isPrimaryTsc: tsc.primary,
                    }));
                }

                if (!single && values.additionalSecurity) {
                    data.AdditionalSecurityInstructions = values.additionalSecurity;
                }
                console.log(data)
                practicalMutation
                    .mutateAsync({
                        body: data,
                        type: single ? 'PUT' : 'POST',
                    })
                    .finally(() => {
                console.log(data)
                        setSubmitting(false);
                        setLoading(false);

                    });
            })
            .catch(() => {
                setSubmitting(false);

                setLoading(false);

                setError('Something went wrong, please try again later');
            });
    };

    return (

        <>
            <div id="main-content">
                <div className="cco_content_area cco_content_area_0">
                    <article className="react-container">

                        <div className="cco_section cco_section_0">
                            <div className="cco_row">


                                <div className="cco_col">
                                    <div className="cco_text cco_text_5">
                                        <div className="cco_text_inner">
                                        {single ? <h1>{t('NewPracLocation.newpracloc.PracticalTestSite')}</h1> : <h1>{t('NewPracLocation.newpracloc.AddPracticalTestSite')} {creditInfo && <AuthorizeSessionTimer startTimer={creditInfo} />
                           }</h1>}
                                            {single && eotData?.TestSiteNumber && <p>{eotData?.TestSiteNumber}</p>}
                                        </div>
                                    </div>

                                    {'idle' !== eotQuery.fetchStatus && eotQuery.isLoading ? (
                                        <Loader />
                                    ) : eotQuery.error ? (
                                        <p>{eotQuery.error.message}</p>
                                    ) : (
                                        <Formik validate={values => {
                                            const errors: FormikErrors<IValuesPrac> = {};

                                            if (single &&
                                                values.prevState.fullName !== values.fullName &&
                                                values.termsAndConditions === values.prevState.termsAndConditions) {
                                                errors.termsAndConditions =
                                                    'Because you changed the full name field now you have to submit a new Terms & Conditions file';
                                            }

                                            return errors;
                                        }}
                                            onSubmit={submitHandler}
                                            validationSchema={single ? singlePractical : newPractical}
                                            initialValues={!eotId
                                                ? initialValues
                                                : {
                                                    ...initialValues,
                                                    prevState: {
                                                        fullName: eotData?.EOTTSRep.FullName || '',
                                                        canBeEdited: 'Approved' === eotData?.Status,
                                                        termsAndConditions: eotData?.EOTTSRep.TermsAndConditionsFile,
                                                    },
                                                    zip: eotData?.Zip || '',
                                                    city: eotData?.City || '',
                                                    street1: eotData?.Address1 || '',
                                                    street2: eotData?.Address2 || '',
                                                    email: eotData?.EOTTSRep.Email || '',
                                                    state: eotData?.StateProvince.Id || 'default',

                                                    sitePlan: eotData?.SitePlanFile || null,
                                                    doorSign: eotData?.PhotoOfSignOnDoorFile || null,
                                                    termsAndConditions: eotData?.EOTTSRep.TermsAndConditionsFile,

                                                    attestation: attestations,
                                                    PracSiteTypes: examoffered,
                                                    PracticalAttestation: PracticalAttestation,
                                                    companyName: eotData?.CompanyName || '',
                                                    fullName: eotData?.EOTTSRep.FullName || '',
                                                    phoneNumber: eotData?.EOTTSRep.Phone || '',
                                                    country: eotData?.Country.Id || 'default',
                                                    emailConfirmation: eotData?.EOTTSRep.Email || '',
                                                    additionalSecurity: eotData?.AdditionalSecurityInstructions || '',
                                                    isASecureFacility: ('undefined' !== typeof eotData?.IsSecuredSite && eotData?.IsSecuredSite
                                                        ? 'yes'
                                                        : 'no') || 'default',


                                                }}
                                        >
                                            {({ submitForm, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => {
                                                return (
                                                    <form
                                                        className="cco_form"
                                                        onReset={handleReset}
                                                        onSubmit={e => {

                                                            if (isSubmitting) { e.preventDefault(); }
                                                            else {
                                                                handleSubmit(e);

                                                            }
                                                        }}
                                                    >
                                                        {Component}

                                                        {single && (
                                                            <div className="cco_form_row">
                                                                <div className="cco_form_col">
                                                                    <div className="cco_form_wrap">
                                                                        <div className="cco_blurb cco_blurb_4">
                                                                            <div className="cco_blurb_main">
                                                                                {productData?.ProductName}
                                                                            </div>
                                                                            <div className="cco_blurb_aside">
                                                                                ${productData?.ProductPrice}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className="cco_form_row">
                                                            <div className="cco_form_col">
                                                                {isLastStep && !single && (
                                                                    <ul className="cco_menu cco_menu_4" style={{ border: '0px' }}>
                                                                        <li className={'cco_menu_item cco_menu_item_checkbox'}>
                                                                            <label className={'cco_form_checkbox cco_form_checkbox_0'} style={{ paddingLeft: "60px" }}>
                                                                                <input
                                                                                    name="termsAndConditions"
                                                                                    id="ccoTermsAndConditions"
                                                                                    type="checkbox"
                                                                                    className={'cco_form_checkbox_input'}
                                                                                    value="termsAndConditions"
                                                                                    onChange={handleTermsAndConditionsChange} />
                                                                                <span className={'cco_form_checkbox_checkmark'} style={{ border: termsAndConditions ? "2px solid #c67b1d" : '1px solid #c67b1d' }}></span>
                                                                                <p style={{ fontSize: "14px" }}>{t('NewPracLocation.newpracloc.note')} <a style={{ color: "#c67b05", padding: "0px", alignItems: "normal" }} href='https://www.nccco.org/nccco/about-nccco/policies/financial-terms-conditions'>{t('NewPracLocation.newpracloc.note2')}</a>.</p>
                                                                            </label>
                                                                        </li>
                                                                    </ul>
                                                                )}


                                                                {error && (
                                                                    <p className="cco_form_message cco_form_message_has_icon cco_form_message_error cco_form_message_error_footer">
                                                                        {error}
                                                                    </p>
                                                                )}

                                                                {practicalMutation.error && (
                                                                    <p className="cco_form_message cco_form_message_has_icon cco_form_message_error cco_form_message_error_footer">
                                                                        {practicalMutation.error.message}
                                                                    </p>
                                                                )}

                                                                {practicalMutation.data && (
                                                                    <p className="cco_form_message cco_form_message_has_icon cco_form_message_success cco_form_message_error_footer">
                                                                       {t('NewPracLocation.newpracloc.added')}
                                                                    </p>
                                                                )}
                                                                <ul className="cco_menu cco_menu_4">
                                                                    {!isSubmitting && !skipUsed && (
                                                                        <li
                                                                            className={classNames('cco_menu_item cco_menu_item_icon', {
                                                                                cco_menu_item_icon_close: isFirstStep || (isLastStep && single),
                                                                                cco_menu_item_icon_caret_left: !isFirstStep && !single,
                                                                            })}
                                                                        >
                                                                            <a
                                                                                onClick={e => {
                                                                                    if (!isFirstStep && !single) {
                                                                                        if (currentStepIndex == 5 && creditInfo === false && initialValues.dataDescriptor === '' && initialValues.dataValue === '') {
                                                                                            setCCError(true);
                                                                                            window.scrollTo(0, 0);
                                                                                            e.preventDefault();
                                                                                        }
                                                                                        else {
                                                                                            back();
                                                                                            e.preventDefault();

                                                                                        }
                                                                                    }
                                                                                }}
                                                                                href={isFirstStep || (isLastStep && single)
                                                                                    ? '/tsc-prac'
                                                                                    : '#'}
                                                                            >
                                                                                {isFirstStep || (isLastStep && single)
                                                                                    ? t('NewPracLocation.newpracloc.back')
                                                                                    : t('NewPracLocation.newpracloc.step')}
                                                                            </a>
                                                                        </li>
                                                                    )}

                                                                    {(!single || (single && 'Approved' === eotData?.Status)) &&
                                                                        !practicalMutation.data && (
                                                                            <>
                                                                                {(!single || skipUsed || isLastStep) && (
                                                                                    <li className={single || !isLastStep
                                                                                        ? classNames('cco_menu_item cco_menu_item_icon', {
                                                                                            cco_menu_item_icon_caret_right: !skipUsed && !isLastStep,
                                                                                            cco_menu_item_icon_checkmark: skipUsed || isLastStep,
                                                                                        }
                                                                                        )
                                                                                        : 'cco_menu_item cco_menu_item_btn'}
                                                                                    >{isSubmitting ? <Loader /> : (
                                                                                        <a href="#" style={{ opacity: currentStepIndex === 6 && termsAndConditions === false ? '.5' : '1', cursor: termsAndConditions === false && currentStepIndex == 6 ? 'not-allowed' : 'pointer' }} onClick={async (e) => {
                                                                                            e.preventDefault();
                                                                                            const isScreenValid = true;

                                                                                            const stepIndex = currentStepIndex as keyof typeof screenedValuesPrac;

                                                                                            if (isLastStep) {

                                                                                                setFieldTouched('submitted', true);
                                                                                                if (termsAndConditions === true) {
                                                                                                    await submitForm();
                                                                                                }
                                                                                                if (single) {
                                                                                                    setLoading(true);
                                                                                                    await submitForm();
                                                                                                }

                                                                                                else {
                                                                                                    e.preventDefault();
                                                                                                }

                                                                                            } else {
                                                                                                if (isScreenValid) {
                                                                                                    if (skipUsed)
                                                                                                        goToLastStep();
                                                                                                    else {
                                                                                                        if (stepIndex === 5 && creditInfo === false && initialValues.dataDescriptor === '' && initialValues.dataValue === '') {
                                                                                                            setCCError(true);
                                                                                                            window.scrollTo(0, 0);
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                        else {

                                                                                                            next();
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                screenedValuesPrac[stepIndex].forEach(field => setFieldTouched(field, true, true));
                                                                                            }
                                                                                        }}>{isLastStep ? single ?  t('NewPracLocation.newpracloc.saei') : t('NewPracLocation.newpracloc.Submit') : skipUsed ?  t('NewPracLocation.newpracloc.sa') :  t('NewPracLocation.newpracloc.next')}</a>
                                                                                    )}
                                                                                    </li>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                    {single &&
                                                                        creditInfo &&
                                                                        isLastStep &&
                                                                        !isSubmitting && (
                                                                            <li className={'cco_menu_item cco_menu_item_btn'}>
                                                                                <button className="cco_form_submit" type={'submit'}>Submit Application </button>
                                                                            </li>
                                                                        )}


                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </form>

                                                );
                                            }}

                                        </Formik>
                                    )}
                                </div>



                            </div>
                        </div>
                        <BlockUI title={single ?  t('NewPracLocation.newpracloc.wait') :  t('NewPracLocation.newpracloc.plz')} blocking={!single ? (termsAndConditions && showLoading) : showLoading || practicalMutation.isSuccess}></BlockUI>
                    </article>
                </div>
            </div>
        </>

    );

};
