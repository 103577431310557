import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import {  msalConfig } from './authConfig';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { setContactId, setTokenId, setUserData } from './tools/utils';

const pca = new PublicClientApplication(msalConfig);
if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
  pca.setActiveAccount(pca.getAllAccounts()[0]);
  setContactId(pca.getActiveAccount().idTokenClaims.extension_ContactId)
  setTokenId(pca.getActiveAccount().idToken)
}

pca.addEventCallback((event:any) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload?.account
  ) {
      setUserData(event.payload);
    console.log(event);
    pca.setActiveAccount(event.payload.account);
  }
});


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
if (!rootElement) throw new Error('Failed to find the root element');
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <MsalProvider instance={pca}>
    <App />
    </MsalProvider>
    </BrowserRouter>

  </React.StrictMode>
);
reportWebVitals();
