import React from 'react';

import { Loader } from '../../components/Loader';
import { useMyCredential } from '../../hooks/useMyCredential';

import { useTranslation } from 'react-i18next';

export const Resouces = () => {

    const {t}=useTranslation();
    // const { id } = useLocation().state;
    const contactcredentialId = window.location.pathname.split('/')[2];

    const myCredentialQuery = useMyCredential(contactcredentialId);
    const myCredentialData = myCredentialQuery.data?.data?.Data;
    const proctorCredential =  (myCredentialData?.EarnedCredential.StatusReason === 'Approved' || myCredentialData?.EarnedCredential.StatusReason==="PendingRenewal")
    ;

//     const handleClick=(event)=>{
//      event.preventDefault();
//  //    const form= document.getElementById('myForm') as HTMLFormElement;
//  //    form.submit();
//          const token = getToken();  // Function to get the token
//          const redirect = encodeURIComponent('https://trueb2c.live/testing-personnel');
//          const targetUrl = `https://trueb2c.live/?code=${token}&state=${state}`;
         
//          window.open(targetUrl, '_blank'); 
//      }
    return (

        <div id="main-content">
            <div className="cco_content_area cco_content_area_0">
                <article className="react-container">

                    <div className="cco_section cco_section_0">
                        <div className="cco_row cco_row_8">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong>
                                            <a href="/testing-personnel">{proctorCredential ? t("myCredential.breadcrumbsLink"):''}</a>
                                        </strong>{' '}
                                        {proctorCredential ? '>'
                                        :''}    {proctorCredential ? myCredentialData?.EarnedCredential?.Name + ' Resources' : ''}      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cco_row" style={{ marginBottom: '20px' }}>
                                <div className="cco_col">
                                    {myCredentialQuery.isLoading ? (
                                        <Loader />
                                    ) : myCredentialQuery.error ? (
                                        <p>{myCredentialQuery.error.message}</p>
                                    ) : !proctorCredential ? (
                                        <div className="cco_text cco_text_12">
                                            <div className="cco_text_inner">
                                                <p>
                                                    Our records indicate that you are not authorized to access this
                                                    page. Please contact CCO if you have any questions.{' '}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                   
               

                        <div className="cco_row cco_row_2">
                            <div className="cco_col">
                                <div className="cco_text cco_text_2">
                                    <div className="cco_text_inner">
                                        <h1 className='entry-title main_title'>{myCredentialData?.EarnedCredential?.Name || ''} {proctorCredential ? t("myCredential.Resources") :''}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cco_row">
                            <div className="cco_col">
                             
                                    <div className="">

                                        <div className="">
                                        </div>
                                        {/* Credential Description */}
                                        {myCredentialData?.EarnedCredential.CredentialLocalizationDetails && (
                                            <div className="cco_card_footer">
                                                <div className="cco_text">
                                                    <div className="cco_text_inner" dangerouslySetInnerHTML={{ __html: myCredentialData?.EarnedCredential?.CredentialLocalizationDetails || '' }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                            
                            </div>

                        </div>


      
                    </div>
                </article>
            </div>
        </div>
    );
};