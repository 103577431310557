import type { Address } from '../types/dashboard';
import moment from 'moment';
import { tz } from 'moment-timezone';
import { IUserData } from '../types/userdata';
import { userBasicData } from '../types/basicdata';


var contactId;
var tokenId;

export function setTokenId(_tokenId) {
    tokenId = _tokenId;
}
export enum StatusReason
{
    Pending = "Pending",
    PendingAudit = "PendingAudit",
    Inactive = "Inactive",
    PendingCertification = "PendingCertification",
    Approved = "Approved",
    Expired = "Expired",
    HOLD = "HOLD",
    Retired = "Retired",
    PendingRenewal = "PendingRenewal"

}

export function setUserData(payload) {
    const data: IUserData = {
        accessToken: payload.accessToken,
        tokenExpiry: payload.expiresOn,
        contactId: payload.idTokenClaims.extension_ContactId
    }
    setTokenId(data.accessToken);
    setContactId(data.contactId);
    const stringObject = JSON.stringify(data);
    localStorage.setItem('msal.userData', stringObject);
}

export function getUserData() {
    const data = localStorage.getItem('msal.userData');
    return JSON.parse(data) as IUserData;
}

// export function getTokens() {
//     const keylist = localStorage.getItem('msal.token.keys.'+REACT_APP_CLIENT_ID);
//     const parced=JSON.parse(keylist);
//     const accessToken = localStorage.getItem(parced);
//     return JSON.parse(data) as IUserData;
// }

export function setContactId(_contactId) {
    // localStorage.setItem('contactId',_contactId);
    contactId = _contactId;
}
export function getToken() {
    const data = getUserData().accessToken;
    return data ? data : tokenId
}

export function getContactId() {
    const data = getUserData();
    return data ? data.contactId : null;
}

const {
    REACT_APP_Computer_Based_Testing_URL,
    REACT_APP_Online_Written_Testing_URL,
    REACT_APP_Paper_Pencil_Testing_URL,
    REACT_APP_Practical_Testing_URL,
    REACT_APP_Test_Administrations_URL,
} = process.env;
export const formatAddress = (address: Address) => {
    if (!address.Street1 || !address.City || !address.Zip) {
        return null;
    }

    return (
        (address.Street1 ? address.Street1 + ', ' : '') +
        (address.Street2 ? address.Street2 + ', ' : '') +
        (address.City ? address.City + ', ' : '') +
        (address.State ? address.State + ', ' : '') +
        (address.Zip ? address.Zip : '')
    );
};

export const NullBadgeUrl = 'https://ncccoimages.blob.core.windows.net/%24web/mycco/credentialbadges/Null%20Badge.png';
export const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
export const notAllowedButtons=['EOT Coordinator Core','Practical Coordinator Core','MBL Workshop','Telehandler Workshop']
const completePreRequisites = { button_text: 'Open Online Learning Center', button_url: '' };
const manageMyTestSites = { button_text: 'Manage My EOT Test Sites', button_url: '/tsc-eot' }
const manageMyPracTestSites = { button_text: 'Manage My Prac Test Sites', button_url: '/tsc-prac' }
export type DateFormats = 'MMDDYY' | 'DDMMYY' | 'YYYYMMDD' | 'DD MMM YY' | 'YY-MM-DD' | 'YYYY-MM-DD' | 'DD-MM-YY' | 'YY-MM-DD HH:mm' | 'YYYY-MM-DD HH:mm' | 'DD-MM-YY HH:mm' | 'HH:mm:ss' | 'HH:mm A' | 'ddd YY' | 'ddd YYYY' | 'DD MMM YYYY' | 'DD MMM YYYY HH:mm' | 'YYYY-MM-DD HH:mm:ss' | 'MM-DD-YY HH:mm:ss' | 'MMMM DD, YYYY' | 'DD MMMM YYYY' | 'DD MMMM YYYY HH:mm' | 'YY MMM DD' | 'YYYY MMMM DD' | 'YY MMM DD HH:mm' | 'HH:mm A ddd DD MMM YY' | 'DD MMM YY HH:mm A' | 'DD-MM-YY HH:mm A' | 'DD MMM YY HH:mm A' | 'YYYY MMMM DD HH:mm A' | 'DD-MM-YY HH:mm A ddd' | 'DD MMMM YYYY HH:mm A' | 'YY-MM-DD HH:mm A ddd' | 'YYYY MMMM DD HH:mm A ddd' | 'DD MMM YY HH:mm A ddd' | 'HH:mm:ss A' | 'HH:mm:ss A ddd DD MMM YY' | 'DD MMM YY HH:mm:ss' | 'YY-MM-DD HH:mm:ss' | 'YYYY-MM-DD HH:mm:ss A' | 'YY MMM DD HH:mm:ss A' | 'YYYY MMMM DD HH:mm:ss A' | 'DD MMM YY HH:mm:ss A' | 'DD MMMM YYYY HH:mm:ss A';

export const customURLs =
{
    "registerUrl": "https://NCCCOB2CDev.b2clogin.com/NCCCOB2CDev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUp_TEST&client_id=64f8f40b-475d-4d43-9d0c-8a61a2da18bf&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportal-test.nccco.org%2F&scope=openid&response_type=id_token&prompt=login",
    "onlineWrittenTestingUrl": {
        "button_text": "Written Testing",
        "button_url": REACT_APP_Online_Written_Testing_URL
    },
    "practicalTestingUrl": {
        "button_text": "Practical Testing",
        "button_url": REACT_APP_Practical_Testing_URL
    },
    "computerBasedTestingUrl": {
        "button_text": "Written Testing - CBT (PSI Test Centers)",
        "button_url": REACT_APP_Computer_Based_Testing_URL
    },
    "paperPencilTestingUrl": {
        "button_text": "Written Testing - PPT",
        "button_url": REACT_APP_Paper_Pencil_Testing_URL
    },
    "esoCandidateDashboardUrl": {
        "button_text": "Launch myCCO Exam Hub",
        "button_url": "https://qa7portal.examstudio.com/sso/oidc.aspx"
    },
    "manageMyTestAdministrationsUrl": {
        "button_text": "Manage My Test Administrations",
        "button_url":REACT_APP_Test_Administrations_URL
    }
}
// const testAdministrations={button_text:window?.runtime?.customURLs?.manageMyTestAdministrationsUrl?.button_text, button_url: customURL}

// export const credentialsButtons =
//     [
//         {
//             CredentialsName: "TSC-EOT",
//             MyCredentials: [completePreRequisites, manageMyTestSites]
//         },
//         {
//             CredentialsName: "TAC-EOT",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "Proctor-EOT",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "Test Admin Coordinator-EOT Renewal",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "TSC-Practical",
//             MyCredentials: [completePreRequisites, manageMyPracTestSites]
//         },
//         {
//             CredentialsName: "TAC-Practical",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "TSC-EOT 2023",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "TAC-EOT 2023",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "PEP-AMP",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "PEP-ABC",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "PEP-ABL",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "PEP-LAT",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "PEP-TLL",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "PEP-TSS",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "Practical Coordinator Core",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "EOT Coordinator Core",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "Proctor-Practical",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "MBL Workshop",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "TBT training",
//             MyCredentials: [completePreRequisites]
//         },
//         {
//             CredentialsName: "Proctor TBT Training - $100",
//             MyCredentials: [completePreRequisites]
//         },

//     ];

export const utcToLocalDateTime = (datetime: string) => {
    if (!datetime) {
        return '';
    }
    let timezone = tz.guess(true);
    let tzAbbr = moment.tz(timezone).format('z');
    return moment.utc(datetime).local().format('MM-DD-YYYY hh:mmA') + " (" + tzAbbr + ")";

};

export const isTokenExpired=(tokenExpiry: string)=> {
    const currentTimestamp = Date.now(); 
    return currentTimestamp >= (parseInt(tokenExpiry)*1000);
  }

  export const getExtByMimeType=(mimeType:string)=> {
    switch (mimeType) {
        case 'image/png':
            return 'png';
        case 'image/jpeg':
            return 'jpg';
        case 'image/gif':
            return 'gif';
        // Add more cases for other MIME types as needed
        default:
            return 'unknown';
    }
}
  export const showCardBorder=(expDate:string,setShowcardBorder:any)=>{
    const expirationDate = moment(expDate);
    const currentDate = moment();
    const sixtyDaysBefore = expirationDate.clone().subtract(60, 'days');
    const oneEightyDaysAfter = expirationDate.clone().add(180, 'days');
     // Calculate the number of days remaining
    //  const daysRemaining = expirationDate.diff(currentDate, 'days');

    //  console.log(`${daysRemaining} Days remaining from Current Date: ${currentDate} and Expiry Date ${expirationDate}`);
        const isShowBorder=currentDate.isBetween(sixtyDaysBefore, oneEightyDaysAfter, null, '[]');
    if (isShowBorder) {
        setShowcardBorder(true);
    } else {
        setShowcardBorder(false);
    }
  }
  export const showContactCredentialCardBorder = (expDate: string, setShowcardBorder: any) => {
    if(expDate){
        const expirationDate = moment(expDate);
        console.log('expDate', expirationDate)
        const currentDate = moment();
        console.log(currentDate)
        const sixtyDaysBefore = expirationDate.clone().subtract(60, 'days');
        const isTodayExpiryDate = currentDate.isSame(expirationDate, 'day');
        const isExpiryWithinSixtyDays = currentDate.isBefore(expirationDate) && currentDate.isSameOrAfter(sixtyDaysBefore, 'day');

        if (isTodayExpiryDate || isExpiryWithinSixtyDays) {
            setShowcardBorder(true);
            return true;
        } else {
            setShowcardBorder(false);
            return false;
        }
    }

}
export const getUserBasicData = () => {
    const token =getToken();
    if (token) {
        const parsed = JSON.parse(decodeURIComponent(escape(atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/').padEnd((token.split('.')[1].length + 3) & ~3, '=')))));

        const userData:userBasicData = {
            CandidateId: parsed.extension_CandidateId || '',
            Email: parsed.emails[0] || '',
            FullName: parsed.name || '',
            First:parsed.given_name,
            Last:parsed.family_name
            
        };

        return userData;
    } else {
        const userData:userBasicData = {
            CandidateId: '',
            Email:'',
            FullName:'',
            First:'',
            Last:''
        };
        return userData;
    }
};


export const getDate=(format:DateFormats)=>{
        const formattedDate = moment().format(format);
        return formattedDate;
}





  
  




