import { useMutation } from '@tanstack/react-query';
import { request } from '../services/request';

export const useMutationQuery = () =>
    useMutation<any, Error, any>(async (data: { body: any; type: 'POST' | 'PUT' }) => {
        const jsonResponse = await request({
            data: data.body,
            method: data.type,
            endpoint: 'PUT' === data.type ? 'locations/update-eot-ts' : 'locations/create-eot-ts',
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
