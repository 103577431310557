import { useMutation } from '@tanstack/react-query';
import { generatePDFRequest } from '../services/GeneratePdf';
// type docType = 'scoresheet' | 'singleperson' | '';
export const useGeneratePDF = () => {
    return useMutation(async (data: { body: any;type:string;}) => {
        const jsonResponse = await generatePDFRequest({
            data:data.body,
            endpoint:data.type==='scoresheet'?'PracticalScoreSheet/GeneratePDF':data.type==='singleperson'?'SignalpersonPracticalExam/GenerateExamPDF':'SignalpersonPracticalExam/GenerateCRGPDF',
        });

        if (!jsonResponse.success) {
            throw new Error('Something went wrong');
        }

        return jsonResponse;
    });
};

