import { useQuery } from '@tanstack/react-query';
import { RootObject } from '../types/credentialgroups';
import { request } from '../services/request';

export const useCredentialGroups = () => {
    return useQuery<RootObject, Error>(['credentialsgroups'], async () => {
        const jsonResponse = await request({
            endpoint: 'credentials/credential-groups'
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    },{
    refetchOnWindowFocus: false,  
    });
};
