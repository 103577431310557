import type { RootObject } from '../types/country';

import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export const useCountries = (enabled = true) => {
    return useQuery<RootObject, Error>(
        ['countries'],
        async () => {
            const jsonResponse = await request({
                endpoint: 'locations/get-countries',
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled,
    refetchOnWindowFocus: false,  

        }
    );
};
